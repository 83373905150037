.increment-picker {
  @include clearfix;
  height: 40px;
  width: 160px;

  button {
    background-color: $color-kettle;
    color: $color-white;
    float: left;
    font-size: 34px;
    height: 40px;
    line-height: 40px;
    position: relative;
    width: 40px;
	border-radius: 20px;

    &.less {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.more {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

  }

  .prompt,
  .selection,
  input {
    @include font-roboto('light');
    background-color: $color-lighter;
    border: none;
    border-radius: 0;
    color: $color-kettle;
    display: block;
    float: left;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 80px;

    &.active {
      color: #E94F35;
    }
  }

  .prompt {
    text-align: right;
    width: 40px;

    +input, +.selection {
      color: $color-black;
      width: 40px;
    }
  }


}
