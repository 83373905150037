section.info-boxes {
  .boxes {
    .box {
      margin-bottom: 40px;

      h2 {
        @include font-generic-sans(900);
        background-color: #EFEFEF;
        padding: 30px 20px;
        font-size: 28px;
        line-height: 30px;
        color: black;
        margin: 0;
      }

      strong, div {
        color: #636362;
        display: block;
        font-size: 16px;
        line-height: 1.5em;
        padding: 20px;
      }

      strong {
        font-weight: 500;
        position: relative;

        &:after {
          content: '';
          border-bottom: 1px solid #aaa;
          position: absolute;
          bottom: 0;
          left: 20px;
          width: calc(100% - 40px);
        }
      }

      div {
        font-weight: 300;
      }
    }
  }

  @include breakpoint(medium) {
    @include grid-row;
    .boxes {
      @include grid-layout(2, '.box', 30px);

      .box {
        // @include grid-col(6);
      }
    }
  }

  @include breakpoint(large) {
    .boxes {
      .box {
        strong, div {
          font-size: 18px;
        }
      }
    }
  }

  @include breakpoint(xlarge) {
    padding: 0 45px;
    .boxes {
      .box {
        h2 {
          font-size: 33px;
          padding: 42px 30px;
        }

        strong, div {
          padding: 30px;
        }

        strong {

        }
      }
    }
  }
}