.modal-right-content.checkout-summary .content {

  section {
    margin-bottom: 30px;
  }

  .form {
    margin-bottom: 60px;

    .form-field {
      label {
        // display: none;
      }
    }

  }

  a.button.checkout-add-new-address,
  a.button.checkout-edit-user {
    background: none;
    color: #E94F35;
    height: auto;
    padding: 0;
    width: auto;
  }

  a.button.checkout-edit-user {
    margin-top: 15px;
  }

  a.confirm {
    background-color: $color-avocado;
    width: 280px;
    @include breakpoint(medium) {
      width: 375px;
    }
  }

  .later-date {
    @include clearfix;
    margin-bottom: 44px;  // makes it so confirm button doesn't jump
    .pretty-select {
      display: block;
      margin-bottom: 15px;
      @include breakpoint(medium) {
        float: left;
        margin-bottom: 0px;
        max-width: 250px;
        width: calc( 50% - 8px );

        &:first-child {
          margin-right: 16px;
        }

      }
    }
  }

}

.modal-right-content.create-account {
  div.content .form  {
    .form-field > label {
      display: none;
    }

    .field-group {
      input {
        padding-left: 0;

        &::placeholder {
          color: inherit;
        }
      }
    }
  }
}



.store-locations,
.modal-right-content.checkout-pickup-location {
  .form {
    @include clearfix;
    .form-field {
      float: left;
      max-width: 280px;
      width: calc( 100% - 160px );

      @include breakpoint(medium) {
        max-width: 375px;

        width: calc( 100% - 200px );
      }

    }
    a.button {
      float: left;
      margin-left: 20px;
    }
  }

  .map-view {
    height: 300px;

    .store-marker-info-window {
      @include clearfix;

      .store-info {
        margin-bottom: 12px;
        height: 65px;
        overflow: auto;
      }

      a.button {
        background-color: $color-avocado;
        float: right;
        height: 28px;
        line-height: 28px;
        padding: 0 16px;
        min-width: 0;
        width: auto;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $color-lighter;
      margin: 40px 0;
      padding-bottom: 40px;


      p.store-name {
        color: #000;
        font-size: 22px;
        margin-bottom: 6px;
      }

      // these are the store hours
      >div {
        margin-bottom: 30px;
      }

      a.get-directions {
        margin: 0 15px 20px 0;
      }

      a.select-store {
        background-color: $color-avocado;
      }
    }
  }
}

.modal-right-content {
  &.checkout-finalize,
  &.payment-info {

    @include breakpoint(small only) {
      &:not(.checkout-prepay) .payment-methods {
        padding-bottom: 40px;
      }
    }


    .payment-methods {

      p {
        display: inline-block;
        margin: 0 8px 0 0;
      }


      .rw-select-list {
        margin: 25px 0 40px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-light;
      }

      a.button {
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 0 15px;
        width: 100%;

        &:first-child {
        }

        &:last-child {
          margin-top: 0;
          background-color: $color-aquamarine;
        }

        @media (min-width: 400px) {
          width: auto;
          &:last-child {
            margin-left: 15px;
          }

        }
      }
    }

    .payment-fields form {
      margin: 25px 0 40px;
      // padding-bottom: 15px;
      // border-bottom: 1px solid $color-light;

      h5 {
        @include font-opensans('bold');
        margin-top: 1em;
      }

      input::placeholder {
        color: $color-gravel;
      }

      .form-field.cvv {
        width: 200px;

        input {
          text-align: right;
        }

        .cvv-info {
          @include font-opensans('light');
          color: $color-charcoal;
          display: block;
          position: absolute;
          right: -40px;
          top: 6px;
          border: 1px solid $color-light;
          width: 30px;
          // cursor: pointer;
          height: 30px;
          text-align: center;
          line-height: 28px;
          border-radius: 15px;

          &:hover:after {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='192px' height='159px' viewBox='0 0 192 159' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EGroup 4%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CVV-Pop-Up' transform='translate(-489.000000, -428.000000)'%3E%3Cg id='Group-4' transform='translate(489.000000, 428.000000)'%3E%3Cg id='Group' fill='%23E5E5E5'%3E%3Crect id='Rectangle-6' x='0' y='0' width='192' height='142' rx='11'%3E%3C/rect%3E%3Crect id='Rectangle-7' transform='translate(95.500000, 139.500000) rotate(45.000000) translate(-95.500000, -139.500000) ' x='82' y='126' width='27' height='27' rx='1'%3E%3C/rect%3E%3C/g%3E%3Cg id='Group-3' transform='translate(24.000000, 24.000000)'%3E%3Crect id='Rectangle-4' stroke='%23636361' stroke-width='2' x='0' y='0' width='144' height='92' rx='6'%3E%3C/rect%3E%3Cpath d='M7.95454545,24.4642857 L136.954545,24.4642857' id='Line-2' stroke='%23636361' stroke-width='15' stroke-linecap='square'%3E%3C/path%3E%3Ctext id='123' font-family='Roboto-Regular, Roboto' font-size='14' font-weight='normal' fill='%23000000'%3E%3Ctspan x='94.2080078' y='65'%3E123%3C/tspan%3E%3C/text%3E%3Crect id='Rectangle-5' stroke='%23E94F21' stroke-width='2' x='86' y='49' width='41' height='26' rx='2'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            width: 120px;
            height: 120px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: -112px;
            left: -45px;
            z-index: 999;
          }
        }
      }

      .form-field.save-card-checkbox {
        padding-top: 12px;
        padding-bottom: 4px;
        width: 100%;

        input {
          width: auto;
        }

        em {
          color: $color-kettle;
          display: inline-block;
          margin-left: 10px;
          font-style: normal;
        }
      }
    }

  }
}

.modal-right-content.checkout-finalize {
  .coupons {
    margin-bottom: 40px;
    h4 {
      display: none;
    }

    input {
      @include underline-input;
      margin-right: 20px;
      max-width: 100%;
      width: calc( 100% - 155px );
      @include breakpoint(medium) {
        width: calc( 100% - 200px );
      }
    }

  }

  .payment-methods,
  .payment-fields,
  .tips-section {
    h4 {
      @include font-opensans('light');
      color: $color-avocado;
      font-size: 25px;
    }
  }


  .tips-section {
    margin-bottom: 50px;

    .driver-tip {
      margin-bottom: 40px;

      .tip-buttons {
        @include clearfix;
        margin-top: 25px;

        a {
          border: 1px solid $color-oven;
          border-right: none;
          display: block;
          float: left;
          line-height: 40px;
          color: $color-oven;
          width: 70px;
          text-align: center;

          &.active {
            color: white;
            background-color: $color-oven;
          }

          &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-right: none;
            border-right: 1px solid $color-oven;
          }
        }

        &+ input {
          @include underline-input;
          width: 70px;
          margin-bottom: 0;
          margin-left: 210px;
          text-align: center;
        }
      }
    }
  }

  dl.tip-totals {
    @include pricing-totals;

    dt:last-of-type,
    dd:last-of-type {
      border-top: 1px solid $color-light;
      margin-top: 16px;
      padding-top: 4px;
    }
  }

// section.payment-methods {
//   padding-bottom: 50px;

//   h4 {

//   }

//   h4 + span {
//     display: inline-block;
//     margin-bottom: 2em;
//   }

//   br {
//     display: none;
//   }
// }

// section.tips-section {

// }

  .thankyou-info {
    color: $color-kettle;
  }
  .thankyou-summary {
    color: #E94F35;
    border-bottom: 1px solid $color-light;
    border-top: 1px solid $color-light;
    margin: 25px 0;
    padding: 25px 0;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
