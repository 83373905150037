.meta-heading {
  @include font-roboto('bold', 'slab');
  color: $color-oven;
  display: none;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0.75em;
}

.meta-description {
  @include font-roboto('light');
  color: $color-oven;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 30px;
  display: none;

  p {
    line-height: inherit !important;
  }

  p:last-child {
    margin-bottom: 0;
  }

  b, strong {
    font-weight: 500;
  }

  @include breakpoint(medium) {
    // font-size: 20px;
    // line-height: 29px;
  }

  @include breakpoint(large) {
    // font-size: 26px;
    // line-height: 36px;
  }
}

.order-options .meta-description {
  @include breakpoint(small down) {
    display: block;
  }
}

.order-meta .meta-description {
  @include breakpoint(medium) {
    display: block;
  }

  @include breakpoint(large) {
    // font-size: 20px;
    // line-height: 29px;
  }
}

ul.products > .meta-description,
.product-list-container > .meta-description {
  @include grid-col;
  padding-top: 30px;
  margin-bottom: 0;
  display: block;

  &:after {
    border-bottom: 1px solid $color-light;
    content: '';
    display: block;
    width: 100%;
    padding-top: 30px;
  }
}

.features .wrap .meta-description {
  @include grid-col;
  display: block;

  @include breakpoint(medium) {
    // margin-bottom: 50px;
  }
}

.product-categories + .meta-container {
  .meta-description {
    @include grid-col;
    display: block;

    @include breakpoint(medium) {
      // font-size: 18px;
      // line-height: 25px;
    }
  }
  .meta-heading {
    @include grid-col;
    display: block;
  }
}

.product-categories + .meta-container,
.features .wrap .meta-description,
ul.products > .meta-description,
.product-list-container > .meta-description {
  @include breakpoint(medium) {
    max-width: 50%;
    float: none;
  }
}

// corrections for Deb to fix paddings
section.features.deals:not(.location) {
  margin-top: 30px;
  @include breakpoint(medium) {
    >.wrap {
      >.feature {
        &:nth-of-type(2n+1) {
          clear: none !important;
        }
        &:nth-of-type(2n) {
          // clear: both !important;
        }
      }
    }
  }
}

.meta-container .meta-description {
  margin-bottom: 56px;
}

.order-meta .meta-description {
  margin-bottom: 48px;
}

.order-options .meta-description {
  margin-bottom: 36px;
}

/*
<h1 class="meta-heading">All of Panago's pizzas are made fresh.</h1>
<div class="meta-description"><p>Can’t choose from over 35+ recipe pizzas? With over 40 fresh quality toppings to choose from, create the pizza you’ve always dreamed of. Panago will build your custom creation on hand-tossed crust made in-store daily. Online pizza delivery created by you, delivered directly to you.</p></div>
*/
