.topping-picker {
  @include clearfix;
  margin-bottom: 20px;

  label {
    // this is probably deprecated now but leaving it in for safety (regression)
    @include topping-picker-label;
  }

  .placements {
    float: left;
    margin-right: 40px;

    >div {
      border-radius: 20px;
      cursor: pointer;
      float: left;
      height: 40px;
      position: relative;
      width: 40px;

      &:before {
        background-color: $color-lighter;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        content: '';
        height: 40px;
        position: absolute;
        width: 20px;
        top: 0;
        left: 0;
      }

      &:after {
        background-color: $color-lighter;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        content: '';
        height: 40px;
        position: absolute;
        width: 20px;
        top: 0;
        right: 0;

      }


      &.active {
        &.left:before, &.right:after, &.both:before, &.both:after {
          background-color: #E94F35;
        }
      }

      &.left {
        &:before {
          background-color: $color-gravel;
        }
      }

      &.right {
        &:after {
          background-color: $color-gravel;

        }
      }

      &.both {
        margin-left: 10px;
        margin-right: 10px;

        &:before, &:after {
          background-color: $color-gravel;
        }
      }

    }
  }

  .increment-picker {
    display: none;
    float: left;
    margin-top: 20px;
  }

  &.active {
    .increment-picker {
      display: block;
    }
  }

  @include breakpoint(medium) {
    .increment-picker {
      display: block;
      margin-top: 0;
    }
  }
}
