section.order-panel {
  .order-meta {
    display: none;
  }

  .order-options {
    padding-bottom: 80px;

    @include breakpoint(medium) {
      padding-bottom: 140px;
    }

    >.collapsable {
      margin-bottom: 40px;
    }

    .product-actions {
      margin-top: 30px;

      .button, button {
        width: 160px;
      }

      .secondary {
        background-color: $color-oven;
        margin-right: 10px;
        margin-bottom: 15px;
      }

      .primary {
        @include template-colors(background-color);
		background: #000 !important;
      }

      @include breakpoint(medium) {
        .secondary {
          margin-bottom: 0;
        }
      }
    }
  }

  // .order-options.deals-menu {
  //   h4 {
  //     color: $color-oven;

  //     &:after {
  //       @include font-panago-pseudo-icon(chevron-right);
  //     }

  //     >p {
  //       font-size: 18px;
  //       margin: 10px 0 0;
  //     }
  //   }
  //   button {
  //     @include template-colors(background-color);
  //     margin-top: 40px;
  //     width: 160px;
  //   }
  // }

  // NEW to handle labels outside of topping-pickers.
  .choices {
    >label {
      @include topping-picker-label;
    }
  }

  .ingredient-legend {
    color: $color-kettle;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .ingredient-meta {
    background-color: $color-kettle;
    border-radius: 8px;
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    position: relative;
    top: -1px;
    width: 8px;

    &.gluten {
      background-color: $color-avocado;
    }
    &.primo {
      background-color: $color-cayenne;
    }
    &.favourite {
      background-color: $color-aquamarine;
    }
  }



}

.react-modal-primary {
  ul.products {
    background-color: white;
    left: 0;
    overflow: scroll;
    position: fixed;
	// @include grid-row;
    z-index: $z-top + 1;
  }

  &.react-modal-secondary {
    ul.products {
      overflow: hidden;
    }

    section.order-panel {
      background-color: white;
      left: 0;
      overflow: hidden;
      position: fixed;
      width: 100%;
      z-index: $z-top + 2;
    }
  }

  ul.products,
  &.react-modal-secondary section.order-panel {
	  margin: 0 auto;
	  right: 0;
	  left: 0;
	  max-width: 1920px;
    @include modal-offset-top($modal-panel-tops, small);

    @include breakpoint(medium) {
      @include modal-offset-top($modal-panel-tops, medium);
    }
  }
}



section.order-panel {
  @include clearfix;
  height: 100%;
  position: relative;

  $closeHeight: 48px;

  a.close-order-panel,
  >a {
    color: black;
    font-size: 24px;
    line-height: $closeHeight;
    position: absolute;
    right: 20px;
    top: 0;
  }

  .order-meta, .order-options {
    @include grid-col;
    overflow: scroll;
  }

  .order-meta {
    background-color: $color-light;
    color: $color-oven;
    // display: block;
    padding-top: $closeHeight;
    height: 100%;

    h4 {
      @include price-headline;
    }

    h4 + p {
      margin-bottom: 32px;
    }

    dl {
      @include clearfix;
      margin: 0;
      dt, dd {
        display: block;
        float: left;
        font-weight: 300;
        line-height: 24px;
        margin: 0;
      }

      dt {
        clear: left;
        padding-right: 15px;
        width: 135px;
      }

      dd {
        width: calc(100% - 135px);
      }
    }

    p {
      line-height: 24px;
      margin: 0;
    }

    section {
      border-top: 1px solid $color-gravel;
      padding: 32px 0;
    }
  }

  .order-options {
    height: calc( 100% - #{$closeHeight} );
    top: $closeHeight;
    position: relative;
  }
}

@include breakpoint(medium) {
  section.order-panel {
    .order-meta, .order-options {
      @include grid-col-size(6);
    }

    .order-meta {
      display: block;
    }
  }

  section.order-panel {
    // @include grid-row;
    height: 100%;
    position: relative;

    $closeHeight: 60px;

    a.close-order-panel,
    >a {
      font-size: 30px;
      line-height: $closeHeight;
      right: 15px;
    }

    .order-meta, .order-options {
      @include grid-col(6);
      overflow: scroll;
    }

    .order-meta {
      background-color: $color-light;
      color: $color-oven;
      display: block;
      padding-top: $closeHeight;
      height: 100%;

      h4 {
        @include price-headline;
		color: #000 !important;
      }

      h4 + p {
        margin-bottom: 32px;
      }

      p {
        line-height: 24px;
        margin: 0;
      }

      section {
        border-top: 1px solid $color-gravel;
        padding: 32px 0;
      }
    }


    .order-options {
      height: calc( 100% - #{$closeHeight} );
      top: $closeHeight;
      position: relative;
    }
  }


}

@include breakpoint(xlarge) {
  section.order-panel {
    a.close-order-panel,
    >a {
      right: 30px;
    }
  }
}



a.nutrition-link {
  @include font-roboto('light');
  color: #000;
  line-height: 22px;
  padding-left: 35px;
  position: relative;
  &:before {
    @include font-panago;
    @include font-panago-pseudo-icon(nutrition);
    color: $color-kettle;
    font-size: 50px;
    line-height: 22px;
    position: absolute;
    left: -16px;
  }
}
