.page-template-template-grid-layout {
	main.main {
		&.hide-content {
			z-index: 1000;
		}
	}
}
.sustainability-section{
    background-color: #94feb8;
    overflow: hidden ;
    position: relative;
    transition: background-color .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
}
.sustainability-container{
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    box-sizing: border-box;
    @media (max-width: 767px){
        display: block;
        }
}
.sustainability-column{
    width: 33.333%;
    position: relative;
    display: flex;
    min-height: 1px;

    @media (max-width: 767px){
    width: 100%;

    }

	&#ditch-plastic {
		.premium-flip-front {
			background-image: url('../images/CPP6097_NoPlasticBags_2021.11.26.gif');
		}
        // .modal-header{
        //     background-image: url('../images/Panago_Ico_Expressive_Code_Phone.svg');
        // }
	}

	&#plant-based {
		.premium-flip-front {
			background-image: url('../images/CPP6097_GrowPlantBasedChoices_2021.11.26.gif');
		}
	}
	&#real-ingredients {
		.premium-flip-front {
			background-image: url('../images/CPP6097_NoArtifiialStuff_2021.11.26.gif');
		}
	}

	&#compostable {
		.premium-flip-front {
			background-image: url('../images/CPP6097_SwitchingToCompostable_2021.12.02.gif');
		}
	}
	&#meat {
		.premium-flip-front {
			background-image: url('../images/CPP6097_ChoosingMeatWithCare_2021.11.gif');
		}
	}

	&#leaner-greener {
		.premium-flip-front {
			background-image: url('../images/CPP6097_GoingLeanerAndGreener_2021.12.02.gif');
		}
	}
	&#timeline {
		.premium-flip-front {
			background-image: url('../images/CPP6097_SupportingLocalGoodness_2021.12.02.gif');
		}
	}

	&#back-people {
		.premium-flip-front {
			background-image: url('../images/CPP6097_StandingBehindOurPeople_2021.12.02.gif');
		}
	}
}
.sustainability-widget-wrap.sustainability-element-populated{
    padding: 0px;
    display: flex;
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    align-content: center;
    align-items: center;
}
.sustainability-widget-last{
    background-color: $color-purple;
}
.sustainability-element.sustainability-widget{
    width: 100%;
    position: relative;
}



.premium-flip-main-box{
    height: 480px;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    // span{
    //     display: block;
    // }
    >div{
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    // -webkit-transition: all .6s ease-in-out;
    // transition: all .6s ease-in-out;
    transition-property: all;
    //transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.2, 0.85, 0.4, 1.275);
    transition-delay: 0s;
    }
}
.premium-flip-main-box:hover .premium-flip-front{
    transform:rotateX(0) rotateY(-180deg);
}
.premium-flip-main-box:hover .premium-flip-back{
    transform:none;
}
.premium-flip-front {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #57F48B;
}
.premium-flip-front ,.premium-flip-back{
    position: absolute;
    width: 100%;
    height: 100%;
}
.premium-flip-front.premium-flip-frontrl{
    transition-duration: 1.2s;
    text-align: center;
    background-color: #57F48B;
    background-image: url("");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // -webkit-transform: rotateX(0) rotateY(-180deg);
    // transform: rotateX(0) rotateY(-180deg);
    // transition: all .6s cubic-bezier(.2,.85,.4,1.275);
}
.premium-flip-front-overlay ,
.premium-flip-back-overlay{
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(.1px);
    transform: translateZ(.1px);
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: flex;
}
.premium-flip-front-content-container ,
.premium-flip-back-content-container{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,70px) scale(.9);
    transform: translate3d(0,0,70px) scale(.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.premium-flip-front-content ,
.premium-flip-back-content{
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

//flip Back
.premium-flip-back{
    transition-duration: 1.2s;
    text-align: left;
    background-color: $color-purple;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transform: rotateX(0) rotateY(180deg);
    .premium-flip-box-full-link{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}
.premium-flip-back-title{
    font-family: "neuzeit-grotesk", Sans-serif;
    font-size: 28px;
    font-weight: 900;
    padding: 0% 0% 0% 8%;
    color: #94FFB7;
}
.premium-flip-back-description{
    >p{
    color: #FFFFFF;
    font-family: "neuzeit-grotesk", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 0% 8% 0% 8%;
    }
}
.sustainability-icon-wrapper {
	padding: 30px 8%;
}
.sustainability-icon{
    color: #76F8A1;
    border-color: #76F8A1;
    font-size: 45px;
	display: block;
	width: 45px;
	height: 45px;
	background-image: url('../images/Btn_Download.png');
	background-repeat: no-repeat;
	background-size: contain;
}

.fa-arrow-circle-down{
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.fa-arrow-circle-down:before{
    content: "\f0ab";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.sustainable-overlay {
	position: fixed;
	height: 100%;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: #411557;
	z-index: 9999;
	display: none;
	.modal-slick-content {
		background: #fff;
    }
    .modal-texts{
        padding: 20px 15px 20px 15px;
    }
	.modal-content {
        color: #000;
        min-height: 10em;
        display: flex;
        align-items: center;
        text-align: center;
        >p{
            font-family: "neuzeit-grotesk", Sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
        }
	}
    .modal-header{
        width: 100%;
        text-align: center;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        >img{
            max-width: 280px;
            display: inline-block;
        }
    }
    .modal-counter{
        display: none;
        color: #411557;
        font-family: "stolzl", Sans-serif;
        font-size: 45px;
        font-weight: 900;
        padding: 40px;
    }
    .modal-title{
        text-align: center;
        >h2{
            font-family: "neuzeit-grotesk", Sans-serif;
            font-size: 28px;
            font-weight: 900;
            color: #411557;
        }
    }
	.panago-icon-exit {
		font-size: 50px;
		color: #94FFB7;
	}
}

.carousel-slick{
    display: flex;
    //align-items: center;
    justify-content: center;
    //width: 100vw;
    height: 100vh;
    position: inherit;
    flex-direction: column;
		width: 85vw;
}

.overlay-title{
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px 0 0;
    // display: none;
    >h2{
        color: #FFFFFF;
        font-family: "neuzeit-grotesk", Sans-serif;
        font-size: 38px;
        font-weight: 900;
    }
		a {
			color: #fff;
			text-decoration: underline;
		}
}
.overlay-title, .carousel-slick {
	display: block;
}
.overlay-content {
	display: flex;
    flex-direction: column;
    align-items: center;
}
.overlay-wrapper{
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 80px;
    margin-right: auto;
    margin-left: auto;
}

.modal-slick-content{
    flex-direction: wrap;
    flex-wrap: wrap;
    height:auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-slick-wrapper{
    padding: 15px;
}
.carousel-slick {
	ul.slick-dots li.slick-active button {
		background-color: #94FFB7;
	}

}
#ditch-plastic{

    [data-slick-index="0"]{
        .modal-header{
            background-color: #83DBFF;
        }
    }
    [data-slick-index="1"]{
        .modal-header{
            background-color: #FABAE4;
        }
    }
    [data-slick-index="2"]{
        .modal-header{
            background-color: #FFE93B;
        }
    }
}
#plant-based{
    .modal-texts{
        min-height: 300px;
    }
    [data-slick-index]{
        .modal-header{
            background-color: #FABAE4;
            >img{
                max-width: 200px;
            }
        }
    }

}

#real-ingredients{
    .modal-texts{
        min-height: 18.75em;
    }
    .overlay-title{
        display: flexbox;
    }
    [data-slick-index]{
        .modal-header{
            padding: 25px 0;
            >img{
                max-width: 123px;
                height: auto;
            }
        }
    }
    [data-slick-index="0"]{
        .modal-header{
            background-color: #94FFB7;
        }
    }
		[data-slick-index="2"]{
        .modal-header{
            background-color: #FABAE4;
        }
    }
    [data-slick-index="2"]{
        .modal-header{
            background-color: #FFE93B;

        }
    }
		[data-slick-index="3"]{
        .modal-header{
            background-color: #83DBFF;
        }
    }
    [data-slick-index="1"]{
        .modal-header{
            background-color: #FF585D;
						>img{
                max-width: 136px;
                height: auto;
            }
        }
    }
}
#compostable{
    .modal-counter{
        display:inline;
    }
    .modal-header{
        background-color: #94FFB7;
        padding: 1em;
        >img{
            display: none;
        }
    }
}
#meat{
    .modal-counter{
        display:inline;
        color: #FFFFFF;
    }
    .modal-circle{
        background-color: #411557;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 40px;
        top: 125px;
        position: absolute;
    }
    .modal-divider{
        width: 100%;
        margin: 0 auto;
        margin-left: 0;
        border-top: white 8px solid;
    }
    .modal-header{
        background-color: #94FFB7;
        justify-content: flex-start;
        flex-direction: column;
        text-align: start;
        align-items: flex-start;
        padding: 0 0 3em 0 ;
        >img{
            display: none;
        }
    }
    .modal-slick-wrapper{
        padding: 0;

    }
    .modal-texts{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
		min-height: 290px;
		.modal-title, .modal-content {
			text-align: left;
		}
    }
	.modal-slick-content {
		// min-height: 530px;
		// align-items: flex-start;
	}
}

#timeline{
    .modal-counter{
        display:inline;
    }
    .modal-header{
        background-color: #FABAE4;
        padding: 1em;
        >img{
            display: none;
        }
    }
		.modal-texts {
			.modal-title {
				min-height: 79px;
			}
		}
}
#leaner-greener{
    .modal-header{
        padding: 30px 0;
        >img{
            max-width: 160px;
            height: auto;
        }
        >span{
            padding: 0;
        }
    }
    [data-slick-index="0"]{
        .modal-header{
            background-color: #83DBFF;
        }
    }
    [data-slick-index="1"]{
        .modal-header{
            background-color: #FABAE4;
        }
    }
    [data-slick-index="2"]{
        .modal-header{
            background-color: #FFE93B;
        }
    }
    [data-slick-index="3"]{
        .modal-header{
            background-color: #FF8E0B;
        }
    }
    [data-slick-index="4"]{
        .modal-header{
            background-color: #83DBFF;
        }
    }
    [data-slick-index="5"]{
        .modal-header{
            background-color: #FF585D;
        }
    }
}
#back-people{
    .modal-texts{
        min-height: 18.75em;
    }
    [data-slick-index="0"]{
        .modal-header{
            background-color: #83DBFF;
        }
    }
    [data-slick-index="1"]{
        .modal-header{
            background-color: #FFE93B;
        }
    }
    [data-slick-index="2"]{
        .modal-header{
            background-color: #94FFB7;
        }
    }
    [data-slick-index="3"]{
        .modal-header{
            background-color: #FABAE4;
        }
    }
}
.js-close-modal {
	position: absolute;
	top: 50px;
	right: 50px;
	font-size: 18px;
	color: #fff;
	z-index: 100;
	&:hover {
		cursor: pointer;
	}
}

.standard-page {
	.entry-content {
		h1 {
			font-size: 90px;
			font-weight: 900;
			line-height: 1;
			color: $color-purple;
			margin-bottom: 20px;
		}
	}
}
