
// INDEX

.modal-right-content.account-index .content {
  p {
    @include font-roboto('light');
    color: $color-oven;
    line-height: 23px;
    margin-bottom: 30px;
  }
  ul {
    border-top: 1px solid $color-light;

    li {
      a {
        &:after {
          @include font-panago-icon;
          @include font-panago-pseudo-icon(chevron-right);
        }
      }
    }
    // sucks we have to move this here. it's cause it overrides the line-height from above mixins.
    @include account-ul;
  }
}

// REPEAT LAST ORDER
.modal-right-content.account-last-order .content {
  .cart-totals{
      border-top: 1px solid $color-light;
      border-bottom: 1px solid $color-light;
      padding-bottom: 20px;
      padding-top: 20px;
      margin-bottom: 30px;
  }
}

// ORDER HISTORY
// SAVED CREATIONS
.modal-right-content.account-saved-orders .content,
.modal-right-content.account-saved-creations .content,
.modal-right-content.account-order-history .content {
  >ul {
    @include account-ul;
    >li {

      border-bottom: 1px solid $color-light;

      .collapsable {
        margin: 0;

        .choices {
          padding-bottom: 30px;
        }
        .cart-totals {
          display: none;

        }
        .cart-actions {
          padding-left: 30px;
        }

      }
    }
  }

}

// SAVED CREATIONS
.modal-right-content.account-saved-orders .content,
.modal-right-content.account-saved-creations .content {
  >ul {
    @include account-ul;

    >li {
      .collapsable {
        .cart-actions {
          padding-left: 0;
          margin-top: 20px;

          button {
            background-color: $color-avocado;
            min-width: 100%;
            margin-bottom: 15px;
          }

          a.button {
            background-color: #E94F35;
            width: 100%;
          }

          @include breakpoint(medium) {
            button {
              min-width: 160px;
              margin-right: 15px;
              width: auto;
            }
            a.button {
              min-width: 130px;
              width: 130px;
            }
          }
        }
      }
    }
  }
}

.modal-right-content.account-saved-orders .content {
  >ul {
    @include account-ul;

    >li {
      .collapsable {
        .cart-actions {
          padding-left: 30px;
        }
      }
    }
  }
}


// EDIT MY ADDRESSES
.modal-right-content.account-addresses .content {
  >ul {
    @include account-ul;

    >li {
      border-bottom: 1px solid $color-light;

      &:last-child {
        // border-bottom: none;
      }

    }
  }

}


.modal-right-content.edit-account-info .content,
.modal-right-content.account-addresses .content {
  .form {
    margin-bottom: 60px;
    margin-top: 15px;

    .field-group {

    }

  }
}


// EDIT MY ACCOUNT INFO


// CREATE

// LOGOUT
