// this is for sliding the menu up and down on scroll. classes applied via JS.
.headroom {
    .menu-centered {
      // transition: transform 300ms ease, margin-left 300ms ease;
    }

    &.headroom--top .menu-centered,
    &.headroom--pinned .menu-centered {
      transform: translateY(0%);
    }

    &.headroom--top.reveal-sidebar .menu-centered,
    &.headroom--pinned.reveal-sidebar .menu-centered {
      transform: translateX(0);
    }

    /*&.headroom--unpinned*/ .menu-centered {
      transform: translateY(-100%);
    }
    &/*.headroom--unpinned*/.reveal-sidebar .menu-centered {
      transform: translate(0, -100%);
    }

    &.headroom--not-top .menu-centered {
     // position: fixed;
      // top: 60px;
      // width: 100%;
      // left: 0;
      // z-index: $z-top;
    }
}

.modal-right-content>header,
.modal-right-content>div.content,
.modal-right-content:before,
.menu-centered,
.location-choice-panel,
// .page-callout-fixed-h2 h2,
.main {
  // will-change: transform;
  // transition: margin-left 300ms ease;
  background-color: white;
  transition: transform 300ms ease;
  z-index: 1;

  .reveal-sidebar & {
    // margin-left: -$sidebar-width;
    overflow: hidden;
    transform: translateX(0);
  }
}

.reveal-sidebar header.main {
  overflow: visible;  /// to show the bubble pointer
}

.menu-centered {
  // background-color: $color-lighter;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $z-top;
	  top: map-get($header-heights, small);
//   top: calc(91px + var(--simple-banner-height));

  &:before {
    background-color: #f4f4f4;
    content: '';
    height: 100%;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;

  }

  @include breakpoint(medium) {
	  top: map-get($header-heights, medium);
//     top: calc(111px + var(--simple-banner-height));
  }

  ul.menu {
    @include clearfix;
    display: inline-block;
    list-style: none;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    padding-top: 15px;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: table-cell;
	     padding: 0 5px;
      vertical-align: middle;
      a {
        @include font-roboto('bold');
        color: #aeaeae;
        display: block;
    		font-size: 18px;

        line-height: 1;
        margin: 0 10px;
        padding: 0;
        position: relative;
        text-align: center;
        text-transform: capitalize;

        &:before {
          @include font-panago;
          background-color: $color-transparent;
          // border-radius: 50px;
          color: $color-purple;
          content: '';
          font-size: 48px;
          display: inline-block;
          left: 0;
          position: absolute;
          top: 0;

          transition: transform 0.2s linear;
        }


        &.wings {
          white-space: nowrap;
          text-indent: -9px;
          &:before {
            text-indent: 0;
          }
        }

        $markupMap: (
          pizza: pizzas,
          deals: deals,
          salads: salads,
          sides: sides,
          breads: breadstuff,
          desserts: dessert,
          drinks: beverages,
          breaddip: dips
        );

        @each $name, $className in $markupMap {
          &.#{$className}:before {
            @include font-panago-pseudo-icon($name);
          }
        }

		&.deals {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Deals.svg');
				background-repeat: no-repeat;

			}
			&:hover {
				&:before {
					// background-position: 25px -18%;
          transform: scale(90%);
				}
			}
		}
    &.everyday-value {
      &:before {
        content: '';
        background-image: url('../images/Panago_Ico_Value_New_Button.svg');
        background-repeat: no-repeat;
      }
      &:hover {
        &:before {
          // background-position:-108px -17%;
          transform: scale(90%);
        }
      }
    }
		&.pizzas {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Pizza.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position:-108px -17%;
          transform: scale(90%);
				}
			}
		}
		&.salads {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Salads.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -245px -15%;
          transform: scale(90%);
        }
			}
		}
		&.wings {
			&:before {
				content: '';
				background-image: url('../images/svg-menu.png');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -376px -17%;
          transform: scale(90%);
				}
			}
		}
		&.breadstuff {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Salads.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -517px -20%;
          transform: scale(90%);
				}
			}
		}
		&.dessert {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Dessert.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -655px -20%;
          transform: scale(90%);
				}
			}
		}
		&.beverages {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Beverages.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -795px -20%;
          transform: scale(90%);
				}
			}
		}
    &.sides {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Sides.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -795px -20%;
          transform: scale(90%);
				}
			}
		}
		&.dips {
			&:before {
				content: '';
				background-image: url('../images/Panago_Ico_Dips.svg');
				background-repeat: no-repeat;
			}
			&:hover {
				&:before {
					// background-position: -950px -20%;
          transform: scale(90%);
				}
			}
		}
      }
    }
  }

  @include menu-height-size(small);
	height: 160px;
	ul.menu {
		height: 160px;
		li a {
			padding-top: 110px;
			width: 100px;
		  &:before {
		 height: 100px;
		 line-height: 100px;
		 width: 100px;
		  }
	  }
	}
  @include breakpoint(medium) {
    @include menu-height-size(medium);
	height: 160px;
    ul.menu {
      padding-top: 18px;
	  height: 160px;

      li a {
        // font-size: 16px;
        margin: 0 14px;

        &:before {
          font-size: 60px;
		  height: 100px;
		  line-height: 100px;
		  width: 100px;
        }

        &.wings {
          text-indent: -1px;
        }
      }
    }
  }

}
