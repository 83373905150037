.page-callout-fixed-h2 h2,
.standard-page .hero h2 {
  @include font-opensans('light');
  background-color: $color-mushroom;
  bottom: 0;
  display: block;
  font-size: 16px;
  height: map-get($callout-heights, small);
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0 15px;
  position: absolute;
  width: 100%;

  div.subtitles {
    line-height: 1.3em;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  label {
    color: white;
    display: inline-block;
    // padding-right: 10px;
  }

  span {
    color: $color-cabbage;
    a {
      color: inherit;
    }
  }

  @include breakpoint(medium) {
    font-size: 22px;
    height: 80px;// map-get($callout-heights, medium);
    padding: 0 20px;
    width: 66.66667%;

    &.rows-2 {
      font-size: 18px;
    }

    label {
      // padding-right: 20px;
    }
  }
  @include breakpoint(large) {
    padding: 0 30px;
    width: 66.66667%;
  }
  @include breakpoint(xlarge) {
    width: 50%;
  }
}

@mixin page-content-styles {

}

.page-callout-fixed-h2 {
  display: none;
  height: map-get($callout-heights, small);
  position: fixed;
  top: map-get($callout-tops, small);
  transition: transform 300ms ease;
  width: 100%;
  z-index: $z-top;

  @include breakpoint(medium) {
    height: 80px;
    top: map-get($callout-tops, medium);
  }

  @include breakpoint(xxlarge) {
    right: 50%;
    max-width: $global-width;

    h2 {
      // float: right;
      // WTF. Why doesn't float:right work here!!??
      left: 100%;
      transform: translateX(-100%);
    }
  }

  .reveal-sidebar & {
    transform: translateX(-$sidebar-width);
  }

  .headroom--pinned & {
    transform: translateY(map-get($menu-header-heights, small));

    @include breakpoint(medium) {
      transform: translateY(map-get($menu-header-heights, medium));
    }

    @include breakpoint(xxlarge) {
      transform: translateY(map-get($menu-header-heights, medium));
    }

  }

  .headroom--pinned.reveal-sidebar & {
    transform: translate(-$sidebar-width, map-get($menu-header-heights, small));

    @include breakpoint(medium) {
      transform: translate(-$sidebar-width, map-get($menu-header-heights, medium));
    }
  }
}

.page-callout-fixed {
  .page-callout-fixed-h2 {
    display: block;
  }
}

.standard-page {
  @include grid-row;


  .hero {
    position: relative;

    .image {
      // @include aspect-image(1140, 570);
      @include fixed-height-image(250px);
    }

    h1 {
      @include font-roboto('bold', 'condensed');
      bottom: 80px;
      color: white;
      font-size: 40px;
      left: 0;
      line-height: 42px;
      margin: 0;
      padding: 0 15px;
      position: absolute;
      // text-transform: uppercase;
      width: 100%;
    }


    @include breakpoint(medium) {
      h1 {
        bottom: 105px;
        font-size: 64px;
        line-height: 68px;
        padding: 0 20px;
        width: 66.66667%;
      }
      .image {
        padding-bottom: 400px;
      }
    }

    @include breakpoint(large) {
      h1 {
        font-size: 80px;
        line-height: 80px;
        padding: 0 30px;
        width: 66.66667%;
      }
      .image {
        padding-bottom: 470px;
      }
    }

    @include breakpoint(xlarge) {
      h1 {
        font-size: 80px;
        width: 50%;
      }

      .image {
        padding-bottom: 570px;
      }

    }


  }

  .entry-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding: 35px 100px 50px;

    @include breakpoint(medium) {
      padding: 40px 20px 60px;
    }

    @include breakpoint(large) {
      padding: 60px 30px 80px;
    }

    h2 {
      color: $color-cabbage;
    }

    h3 {
      color: $color-mango;
    }

  }


}

// These basic styles will also be shown in the tinymce editor
body#tinymce.post-type-page,
.standard-page .entry-content {
  color: $color-oven;

  h1 {

  }


  h2 {
    @include font-roboto('bold', 'slab');

    font-size: 24px;
    line-height: 1.2em;
    margin-bottom: 20px;
  }

  h3, p, ol, ul {
    font-size: 16px;
    line-height: 22px;
  }

  h3 {
    @include font-roboto('light');
    margin: 20px 0 0;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: #000;
	text-decoration: underline;
  }

  hr {
    margin: 35px 0;
  }

  ul, ol {
    @include font-roboto('light');
    background-color: $color-lighter;
    margin: 30px -15px;
    padding: 20px 30px;
    li {
      margin: 10px 0;
    }
  }

  @include breakpoint(medium) {
    h3, p, ol, ul {
      font-size: 18px;
      line-height: 26px;
    }

    ul, ol {
      margin: 30px -20px;
      padding: 30px 50px;

    }
  }
  @include breakpoint(large) {
    ul, ol {
      margin: 30px 0px;
    }
  }

}
