#message_bar {
  >div {
    background-color: $color-aquamarine;
    color: white;
    overflow: hidden;
    padding: 20px 30px;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.5s ease;
    width: 100%;
    z-index: $z-top + $z-top;

    .message-bar-title {
      font-weight: 400;
    }

    .message-bar-description {

    }

    .panago-icon-exit {
      cursor: pointer;
      font-size: 22px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      right: 5px;
      text-align: 30px;
      top: 5px;
      width: 30px;
    }


    &.error {
      background-color: #E94F35;
    }

    &.info {
      background-color: $color-mango;
    }

    &.message-bar-open {
      transform: translateY(0);
      // animation: slidedown 1s ease, slideup 1s ease 4s;
      // animation-fill-mode: forwards;
    }
  }
}
