section.image-banner-info {
  @include grid-row;

  .banner {
    img {
      width: 100%;
    }
  }

  .info {
    background-color: #464646;
    padding: 40px 20px;
    text-align: center;

    h1, h3 {
      margin: 0 auto;
      max-width: 700px;
    }

    h1 {
      @include font-generic-sans(900);
      color: white;
      line-height: 1em;
      font-size: 32px;
      margin-bottom: 22px;
    }

    h3 {
      @include font-roboto('light');
      font-size: 18px;
      line-height: 1.5em;
      color: #AAAAAA;
    }
  }

  .description {
    @include grid-col;
    padding: 30px 20px;
    color: #636362;
  
    a {
      color: #FC3E23;
      text-decoration: none;
    }

  }

  @include breakpoint(medium) {
    .info {
      padding: 60px 60px;
      h1 {
        font-size: 40px;
      }

      h3 {
        font-size: 20px;
      }

      h1, h3 {
        br {
          display: none;
        }
      }
    }

    .description {
      padding: 30px 40px;
    }
  }

  @include breakpoint(xlarge) {
    .info {
      padding: 80px 60px;
    }

    .description {
      padding: 50px 90px;
      font-size: 18px;
      line-height: 28px;
    }
  }

}