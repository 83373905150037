@mixin template-colors($att, $root:'') {
  @each $name, $code in $template-colors {
    #{$root} .#{$name} & {
      #{$att}: $code;
    }
  }
}

@mixin root-template-colors($att, $path, $root:'') {
  @each $name, $code in $template-colors {
    #{$root}.#{$name} #{$path} {
     #{$att}: $code;
    }
  }
}

@mixin ar-container($w: 1, $h: 1) {
  height: 0;
  padding-bottom: ($h / $w * 100%);
  position: relative;
  width: 100%;
}

@mixin covered-image {
  .cover {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;

    }
  }
}

@mixin aspect-image($w: 1, $h: 1, $target: 'span') {
  @include ar-container($w, $h);

  #{$target} {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

}

@mixin fixed-height-image($h) {
  @include aspect-image;
  padding-bottom: $h;
}

@mixin floater-button($glyph: false) {
    background-color: $color-charcoal;
    border-radius: 0.4em;
    color: white;
    font-size: 5 / 8 * 100px;
    height: 0.8em;
    line-height: 0.8em;
    position:fixed;
    text-align: center;
    width: 0.8em;
    z-index: $z-top + 5;

    @if $glyph {
      @include floater-button-icon($glyph);
    }

    @include breakpoint(medium) {
      font-size: 100px;
    }
}

@mixin floater-button-icon($glyph) {
  $innerSize: 0.5em;// 50px - half of outer font size

  &:before {
    @include font-panago;
    @include font-panago-pseudo-icon($glyph);
    font-size: $innerSize;
    height: 0.6em / $innerSize * 1em;       //60px;
    left: 0.1em / $innerSize * 1em;         //10px;
    line-height: 0.6em / $innerSize * 1em;  //60px;
    position: absolute;
    top: 0.12em / $innerSize * 1em;       //12px;
    width: 0.6em / $innerSize * 1em;        //60px;
  }
}

@mixin price-headline($astemplate:true) {
  @include font-roboto('normal', 'slab');
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 22px;
  position: relative;

  @if $astemplate {
    @include template-colors(color);
  }

  .price {
    display: inline-block;
    padding-left: 15px;
    position: relative;
    text-align: right;
  }

  .qty_text {
    display: inline-block;
    // margin-left: 0.4em;
  }
}

@mixin price-salebug {
  &:after {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/sale-bug.png');
    content: '';
    height: 26px;
    position: absolute;
    right: -65px;
    width: 45px;
  }
}

@mixin price-headline-sale($onprice:false) {
  @include font-roboto('bold', 'slab');
  @if $onprice {
    span.price {
      @include price-salebug;
    }
  } @else {
    @include price-salebug;
  }
}

@mixin header-height($size) {
  $h: map-get($header-heights, $size);
  $sh: map-get($sub-header-heights, $size);

  body > main {
    padding-top: $h + map-get($menu-header-heights, $size);// !important;
  }

  // body.react-modal-primary > main {
  //   padding-top: map-get($modal-panel-tops, $size);
  // }

  header.main {
    height: $h;
    &:before {
      height: $h;
    }

    > .sub-header {
      height: $sh;
      line-height: $sh;
    }

    > .wrap {
      line-height: $h - $sh;

    }
  }
}

@mixin callout-top {

}

@mixin panel-top {

}

@mixin callout-header($bodyClass) {
  // @include grid-row;
  @include grid-col;
  background-color: $color-lighter;
  color: $color-oven;
  float: none;
  margin-left: 0;
  margin-right: 0;

  // position: fixed;
  // z-index: $z-top + 1;

  h1, h2, h3 {
    @include font-opensans('light');
    color: inherit;
    float: left;
    font-size: 20px;
    margin: 0;
  }
  a {
    color: inherit;
    float: right;
    font-size: 24px;
  }

  @include callout-height(small);

  #{$bodyClass} & {
    position: fixed;
    // top: map-get($callout-tops, small);
    z-index: $z-top + 2;
  }

  @include breakpoint(medium) {
    @include callout-height(medium);

    h1, h2, h3 {
      font-size: 32px;
    }

    a {
      font-size: 30px;
    }


    // #{$bodyClass} & {
    //   top: map-get($callout-tops, medium);
    // }
  }


  #{$bodyClass} & {
    top: map-get($callout-tops, small);

    @include breakpoint(medium) {
//       top: map-get($callout-tops, medium);
		top: "256px";
    }
  }

}

@mixin callout-height($size) {
  $h: map-get($callout-heights, $size);
  height: $h;
  h1,h2,h3 {
    line-height: $h;
  }
  a {
    line-height: $h;
  }
}

@mixin modal-offset-top($tops, $size, $max-height:false) {
  $panelTop: map-get($tops, $size);
  @if $max-height {
    max-height: calc( 100% - #{$panelTop} );
  } @else {
    height: calc( 100% - #{$panelTop} );
  }
  top: $panelTop;
}

@mixin modal-panel($z: $z-top, $tops: $modal-panel-tops, $max-height: false) {
  background-color: white;
  overflow: scroll;
  position: fixed;
  width: 100%;
  z-index: $z;
  @include modal-offset-top($tops, small);
  @include breakpoint(medium) {
    height: auto;
    @include modal-offset-top($tops, medium, $max-height);
  }
}

@mixin wide-columns {
  list-style: none;
  margin: 0;
  padding: 0 0 40px;

  @include clearfix;
  @include grid-layout(1, 'li', $grid-column-gutter);

  @include breakpoint(medium) {
    @include grid-layout(2, 'li', $grid-column-gutter);
  }

  >li {
    margin-top: 40px;
  }

}

@mixin menu-height-size($bp) {
  $size: map-get($menu-icon-size, $bp);
  $h: $size + (2 * map-get($menu-padding, $bp));
  height: $h;

  ul.menu {
    height: $h;

    li {
      a {
        padding-top: $size + 10px;
        width: $size;

        &:before {
          height: $size;
          line-height: $size;
          width: $size;
        }
      }
    }
  }

}

@mixin account-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    >a, h4 {
      @include font-opensans('light');
      border-bottom: none;
      // border-bottom: 1px solid $color-light;
      color: $color-avocado;
      display: block;
      font-size: 22px;
      height: 50px;
      line-height: 50px;
      margin: 0;
      padding: 0 50px 0 0;
      position: relative;
      width: 100%;

      &:after {
        // @include font-panago-icon;
        // @include font-panago-pseudo-icon(chevron-right);
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 20px;
      }

      @include breakpoint(medium) {
        font-size: 25px;
        height: 70px;
        line-height: 70px;
        padding: 0 70px 0 0;

        &:after {
          font-size: 20px;
          height: 70px;
          line-height: 70px;

        }
      }
    }
    >a, h4 {
      // border-bottom: 1px solid $color-light;
    }
  }
}

@mixin underline-input {
  @include font-roboto('light');
  border: 0;
  border-bottom: 1px solid $color-light;
  color: $color-kettle;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 18px;
  max-width: 280px;
  padding: 8px 0;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  &:focus {
    outline: none;
  }

}

@mixin spinner {
  animation: spin 1.5s infinite linear;
  background-image: url('../images/spinner.svg');
  background-position: center;
  background-repeat: no-repeat;
  content: '';
}

@mixin checkbox-radio-button {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:checked {
    +div, +span {

      @include template-colors(color);
	  color: #E94F35 !important;

      &:before {
        background-color: $color-oven;
      }
    }
  }

  &:disabled {
    +div, +span {
      color: $color-kettle;
      cursor: auto;
      &:before {
        background-color: $color-lighter;
      }
    }
  }

  &:checked:disabled {
    +div, +span {
      &:before {
        background-color: $color-oven;
      }
    }
  }

  +div, +span {
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: -1px;
      border-radius: 7px;
      margin-right: 18px;
      background-color: $color-light;
    }

    > span {
      display: inline-block;
      text-align: right;
      width: 85px;

      &:first-child {
        text-align: left;
        width: 75px;
      }

      // @include breakpoint(medium) {
      //   width: 85px;
      // }
    }
  }
}

@mixin topping-picker-label {
  @include font-roboto('light');
  // clear: both;
  color: $color-oven;
  display: block;
  font-size: 16px;
  line-height: 53px;
  text-transform: uppercase;
}

@mixin pricing-totals {
  @include clearfix;
  margin-bottom: 0;

  dt, dd {
    @include font-roboto('light');
    color: $color-charcoal;
    float: left;
    font-size: 17px;
    line-height: 35px;
    margin-bottom: 0;
  }
  dt {
    width: 75%;
  }
  dd {
    text-align: right;
    width: 25%;
  }

  @include breakpoint(375px) {
    dt,dd {
      font-size: 18px;
    }
    dt {
      width: 70%;
    }
    dd {
      width: 30%;
    }
  }

  @include breakpoint(xlarge) {
    dd {
      width: 20%;
    }
  }
}

@mixin react-autocomplete {
  ul {
    background-color: white;
    left: 0;
    list-style: none;
    margin-top: 5px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;

    li {
      @include font-roboto('light');
      color: $color-oven;
      border-radius: 4px;
      cursor: pointer;
      margin: 0;
      padding: 15px 0;
      text-align: center;

      &.react-autosuggest__suggestion--focused,
      &.react-autosuggest__suggestion--highlighted,
      &:hover {
        background-color: $color-lighter;
        // font-weight: bold;
      }

      p {
        margin: 0;
      }

      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

}

/// Set a grid row size
/// Override of the foundation mixin
/// @param {Keyword|Number} $size [$grid-row-width] Maximum size of the row. Set to `expand` to make the row taking the full width.
@mixin grid-row-size($size: $grid-row-width) {
  @if $size == expand {
    $size: none;
  }

  max-width: $size;
  @media only screen and (min-width: 1300px) {
	  max-width: 1920px;
	  padding-left: 100px;
	  padding-right: 100px;
  }

}
