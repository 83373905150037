ul.product-categories {
  list-style: none;
  margin: 0;
  padding: 0 0 40px;
  // @include grid-row;


  >li {
    @include grid-col;
    @include grid-col-collapse;


    margin-bottom: 16px;

    @include breakpoint(medium) {
      @include grid-col-size(6);
      // @include grid-col-uncollapse;
      margin-bottom: 0;
      padding-top: 15px;
      padding-bottom: 15px;
		margin-top: 70px;
      &:nth-child(2n) {
        padding-left: 15px;
      }
      &:nth-child(2n + 1) {
        clear: both;
        padding-right: 15px;
      }

    }

    .category-preview {
      .image-wrap {
        @include aspect-image(1242, 850, '.image');
      }
    }
  }

}

ul.product-groups {
  @include wide-columns;

  >li {
    &:last-child {
      float: right;
    }
    .product-header .product-title h4,
    >h4 {
      @include price-headline(false);
	  font-weight: 700;
      border-bottom: 1px solid $color-light;
      color: $color-oven;
      display: block;
      margin-bottom: 24px;
      padding-bottom: 20px;

    }

    .collapsable {
      margin-bottom: 0;
      h4 {
        border-bottom: none;
        color: $color-oven;
        font-size: 20px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .choices {
        padding-bottom: 50px;

        a.button {
          // @include button-normal;
          @include template-colors(background-color);
          float: right;
          margin-top: 30px;
        }
      }
    }

    @include breakpoint(medium) {
      &:nth-child(2n + 1) {
        clear: none;

      }
    }

    &.on-sale >h4 {
      @include price-headline-sale(true);
    }

  }
}

div.callout {
  @include callout-header('.react-modal-primary');
  @include template-colors(background-color, '.react-modal-primary');
	background: #ACFBBD !important;
  @include breakpoint(large) {
    width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	right: 0;
  }

  .react-modal-primary & {
    color: white;
    left: 0;
    width: 100%;
  }
  h1 {
	  font-weight: 900;
	  color: $color-white;
  }
}

.product-list-container {
  @include grid-row;
	margin-bottom: 100px;

  > .product-list-container {
	  max-width: none;
	  padding-left: 0;
	  padding-right: 0;
		margin-bottom: 0;
  }
}
// .cayenne {
// 	@include grid-row;
// }
ul.products {
  @include wide-columns;
  padding-bottom: 40px;

  >li {
	  margin-bottom: 40px;
	  @include breakpoint(large) {

		&:nth-of-type(odd) {
			padding-right: 80px;

		}
		&:nth-of-type(even) {
			padding-left: 80px;

		}

	}
    &:after {
      // border-bottom: 1px solid $color-light;
      // clear: both;
      // content: none
      // display: block;
      // padding-top: 20px;
      // width: 100%;
    }

    >h4, >h5 {
      @include price-headline(false);
      color: $color-oven;
    }

    .product-header {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 22px;

      h4, .price, .calories {
        @include price-headline(false);
		font-weight: 700;
        color: $color-oven;
        display: block;
        margin-bottom: 0;
      }

      .product-title {
        position: relative;

        .calories {
          font-size: 18px;
        }

      }

      .price {
        padding-left: 20px;
        text-align: right;

        .qty_text {
          display: block;
          font-size: 18px;
        }
      }

      .icons {
        display: inline-block;
        width: 15px;

        img {
          margin-top: 4px;
        }
      }
    }

    >h4 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      .name {
        padding-left: 32px;
        position: relative;

        .icons {
          position: absolute;
        }
      }
    }

    >p {
      color: $color-oven;
      margin-bottom: 20px;
	  font-size: 18px;
    }

    // This is for wings, desserts, etc
    >.collapsable {
      margin-bottom: 40px;
      h4 {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .choices {
        padding-top: 30px;
      }
	  p {
		  font-size: 18px;
	  }
    }

    ol {
      // float: left;
      list-style: none;
      margin: 0 0 30px;
      padding: 0;
      // width: calc(100% - 150px);

      >li {
        display: inline-block;
        margin-right: 16px;

        span, label {
          @include font-roboto('light');
          color: $color-oven;
          font-size: 16px;
          line-height: 1.375;
        }
      }
    }

    .product-actions {
      @include clearfix;
      margin-top: 40px;
    }

    >.collapsable .product-actions a, // dips
    >.product-actions a,              // wings, breads, etc.
    >.product-actions button,
    >a {
      @include button-normal;
      // float: right;
      // @include template-colors(background-color);
      background: $color-black;
	  color: $color-white;
	  border-radius: 20px;
	  font-weight: 700;
		margin-top: 40px;
		font-size: 16px;

    }

    &.on-sale >h4 {
      @include price-headline-sale;
    }

    @include breakpoint(large) {

	  >p {
        margin-bottom: 40px;

      }
      ol {
        // float: left;
        margin: 0;
        // width: calc(100% - 150px);
      }

    }
  }

  &.dips {
    li {
      div.collapsable {
        margin-bottom: 0;
        h4 {
          @include price-headline(false);
          color: $color-oven;

          display: block;

          &.on-sale span {
            @include price-headline-sale;
          }
        }
      }
    }
  }
}

.single-column-menu {
  @include breakpoint(medium) {
    display: none;
  }

}

.double-column-menu {
  display: none;
  @include clearfix;
  @include breakpoint(medium) {
    display: block;
  }

  ul.products {
    @include grid-col(6);
	&:nth-of-type(odd) {
		padding-right: 80px;

	}
	&:nth-of-type(even) {
		padding-left: 80px;

	}
    >li {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}
