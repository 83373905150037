.template-index {
  @include grid-row;
  margin-bottom: 60px;
  margin-top: 30px;
  // max-width: 1200px;

  .sidebar {
    padding: 30px 20px;

    .widget {
      margin-bottom: 20px;

      &.widget_search {
        margin-bottom: 40px;    // search form.
      }

      &.widget_text {
        display: none;
      }
      .single &.widget_text {
        display: block;
      }

      .search-form {
        @include clearfix;

        label {
          span {
            display: none;
          }
        }

        input[type=search] {
          background-color: $color-lighter;
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
          border: none;
          box-sizing: border-box;          
          float: left;
          height: 30px;
          line-height: 30px;
          max-width: calc( 100% - 30px );
          padding: 0 10px;
          width: 180px;
        }

        button {
          background-color: black;
          background-image: url('../images/icon-search.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 16px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border: none;
          box-sizing: border-box;          
          color: white;
          float: left;
          height: 30px;
          line-height: 30px;
          position: relative;
          width: 30px;
        }

      }
    }

    h3, h3 a, h3 a:hover {
      @include font-roboto('bold', 'condensed');
      color: $color-oven;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      a {
        @include font-roboto('light');
        color: $color-kettle;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .posts {
    .alert {
      @include font-roboto('bold', 'condensed');
      font-size: 24px;
      line-height: 24px;
      margin: 40px 0;
      text-transform: uppercase;

    }

    .pagination {
      @include font-roboto('light');
      color: $color-oven;
      text-align: center;
      a,span {
        color: $color-oven;
        display: inline-block;
        margin: 0 10px;
      }

      .current {
        text-decoration: underline;
      }
    }

    article {
      @include clearfix;
      background-color: $color-lighter;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }

      .image {
        @include aspect-image(310, 250);
      }

      .entry-content {
        @include font-roboto('light');
        color: $color-oven;
        min-height: 250px;
        padding: 20px 20px 50px 20px;
        position: relative;

        time {
          display: block;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 5px;
          text-align: right;
        }

        h2, h2 a, h2 a:hover {
          @include font-roboto('bold', 'condensed');
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        p {
          margin-bottom: 10px;          
        }

        p, a, a:hover {
          color: $color-oven;
          font-size: 14px;
          line-height: 21px;
        }

        .excerpt {

        }

        .content {
          display: none;
        }

        .actions {
          @include clearfix;
          bottom: 0;
          height: 25px;
          left: 0;
          margin-bottom: 15px;
          padding: 0 20px;
          position: absolute;
          width: 100%;
          a {
            float: left;
            line-height: 25px;
            margin: 0;

            .single &.more-link {
              display: none;
            }

            &.more-link {
              color: black;

              .open {
                display: none;
              }
              .close {
                display: inline;
              }

              &.closed {
                .open {
                  display: inline;
                }
                .close {
                  display: none;
                }
              }

            }
          }

          .sharing {
            float: right;

            a {
              font-size: 18px;

              &.open + .share-buttons {
                width: 60px;
              }
            }

            .share-buttons {
              float: left;
              overflow: hidden;
              transition: width 0.5s;
              width: 0;
              white-space: nowrap;

              a {
                display: inline-block;
                float: none;
                font-size: 25px;
                height: 25px;
                width: 25px;

                &:first-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      @include breakpoint(medium) {      

        .image {
          @include fixed-height-image(150px);
          float: left;
          width: 190px;
        }

        .entry-content {
          float: left;
          min-height: 250px;
          padding: 20px 20px 50px 20px;
          width: calc( 100% - 190px );
        }
      }

      @include breakpoint(large) {      

        .image {
          @include fixed-height-image(200px);
          width: 248px;
        }

        .entry-content {
          min-height: 250px;
          width: calc( 100% - 248px );

          h2, h2 a, h2 a:hover {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 15px;
          }

        }
      }
      @include breakpoint(xlarge) {      

        .image {
          @include fixed-height-image(250px);
          width: 310px;
        }

        .entry-content {
          min-height: 250px;
          padding: 20px 20px 50px 40px;
          width: calc( 100% - 310px );
          // h2, h2 a, h2 a:hover {
          //   font-size: 24px;
          //   line-height: 24px;
          //   margin-bottom: 20px;
          // }
          .actions {
            padding-left: 40px;
          }          

        }
      }
      @include breakpoint(xxlarge) {      

        .image {
          @include fixed-height-image(250px);
          width: 310px;
        }

        .entry-content {
          min-height: 250px;
          padding: 20px 20px 50px 40px;
          width: calc( 100% - 310px );
        }
      }



    }
  }

  @include breakpoint(medium) {
    .sidebar {
      padding: 0;
      @include grid-col(3);
    }
    .posts {
      @include grid-col(9);
      float: right;
    }
  }


}