section.location-main {
  @include grid-row;

  .main, .map {
    @include grid-col;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .main {
    label, span, div {
      @include font-roboto('light');
      font-size: 15px;
      line-height: 20px;
    }

    ul {
      margin: 30px 0 0;
      padding: 0;
      list-style: none;
      max-width: 400px;

      li {
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          width: 190px;
        }
        // label, span {
        //   display: inline-block;
        // }

        // label {
        //   width: calc(100% - 120px);
        // }
        // span {
        //   width: 120px;
        // }
      }
    }
  }

  @include breakpoint(large) {
    .main, .map {
      @include grid-col-size(6);
    }

    .main {
      float: right;

      label, span, div {
        font-size: 20px;
        line-height: 28px;        
      }
    }
  }

}

section.location-html {
  @include grid-col-row;
  // margin-top: 40px;
  margin-bottom: 40px;

  &.brand {
    margin-top: 0;
  }

  p {
    font-size: 15px;
    line-height: 20px;
  }

  @include breakpoint(large) {
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

section.location-main .main, section.location-html {
  color: $color-oven;

  h1, h2 {
    @include font-roboto('bold', 'slab');
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 0.5em;
  }

  @include breakpoint(large) {
    h1, h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }

}

section.feature-slides.for-location {
  margin-bottom: 50px;
}

section#location-deals {
  .wrap .feature {
    display: none; // hide all by default. We show them with JS depending on the store. 
  }
}
