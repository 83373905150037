header.main {
  // border-bottom: 5px solid white;
  left: 0;
  // overflow: hidden;   // This fixes a problem with the inline-block anchor elements that add height below $header-height
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-header;

  // &:before {
  //   // background-image: url('../images/header-bg.jpg');
  //   // background-position: center center;
  //   // background-size: cover;
  //   content: '';
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  // }

  .sub-header {
    position: relative;
    width: 100%;
    background-color: black;
    // height: 29px;
    top: 0;

    text-align: right;
    color: white;
	.phone-wrapper {
		padding-left: 20px;
	}
	.phone-label {
		font-weight: 400;
		float: left;
		margin: 0 5px 0 0;
	}

    ul {
      padding: 0 0px;
      margin: 0;
      list-style: none;
	  color: #fff;
      font-size: 13px;
      font-weight: 400;
      line-height: inherit;

      li {
        display: inline;
        &:not(:last-child):after {
          content: '|';
        }

        &:last-child a {
          margin-right: 0;
        }
      }
      a {
        color: inherit;
        margin: 0 8px;
      }
    }
  }

  .wrap {
    @include grid-row;
    position: relative;
	padding: 0 15px;
  }

  .phone {
    @include font-generic-sans(400);
    color: #fff;
    // display: none;
    float: left;
    font-size: 16px;

    a {
      color: inherit;
    }
  }


  .logo {
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    text-align: left;

    a {
      background-image: url('../images/panago-logo-2021.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      color: $color-black;
      @include font-generic-sans(900);
      font-size: 55px;
      display: inline-block;
      height: map-get($header-heights, small) - map-get($sub-header-heights, small);
      position: relative;
      // text-indent: -99999px;
      width: 210px;
	  height: 45px;
		margin-top: 10px;
      // vertical-align: middle;
	  @include breakpoint(large) {
		  height: 55px;
			margin-top: 0;
		}
      // &:after {
      //   content: '';
      //   position: absolute;
      //   background-image: url('../images/bubble_pointer.svg');
      //   background-size: contain;
      //   height: 16px;
      //   width: 80px;
      //   left: 50%;
      //   bottom: -15px;
      //   margin-left: -43px;
      // }
    }
  }

  .nav {
    // @include grid-col(6);
    float: right;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    a {
      color: $color-kettle;
      display: inline-block;
      font-size: 32px;
      vertical-align: middle;

      .reveal-sidebar &.right-menu i:before {
        @include font-panago-pseudo-icon(exit);
      }
    }
  }

  nav {
    // @include font-roboto('black');
    @include font-generic-sans(800);
    font-size: 18px;
    // position: absolute;
    // right: 20px;
    // top: 50%;
    // transform: translateY(-50%);
	display: block;
	float: right;
	padding: 10px 0 0;

    a {
      color: inherit;
	  font-size: 25px;
    }

    ul {
      @include clearfix;
      list-style: none;
      padding: 0;
      margin: 0;
    }
    > ul {
      > li {
        display: none;
        float: left;
        padding: 0 13px 8px;
        position: relative;
        margin-top: 24px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.right-menu-toggle {
          display: block;
		  margin-top: 7px;

          a {
            background-image: url('../images/icon-hamburger.svg');
            background-position: center 2px;
            background-repeat: no-repeat;
            display: inline-block;
            line-height: 1;
            font-size: 32px;
            vertical-align: middle;
            width: 32px;
            color: transparent;
            &:before {
              color: $color-black;
            }
          }
          @include breakpoint(large) {
            display: none;
          }
        }

        @include breakpoint(large) {
          display: block;
        }

        a.sub-menu-active + .sub-menu {
          display: block;
        }

        &.current-menu-item {
          a {
            position: relative;
            &:after {
              position: absolute;
              left: 0;
              bottom: -6px;
              content: '';
              height: 4px;
              width: 100%;
              background-color: #C8C8C8;
            }
          }
        }

        >.sub-menu {
          opacity: 0;
          visibility: hidden;
          transform:translateY(-5px);
          background-color: $color-black;
          position: absolute;
          width: 250px;
          padding: 20px 15px;
          top: 100%;
          left: 0;
          color: $color-white;
          transition: all .3s ease;

          li {
            display: block;
            margin: 5px 0;
            font-weight: 400;

            a:hover {
              color: $color-kettle;
            }
          }
        }

        &.menu-item-has-children:hover {
          .sub-menu {
            opacity: 1;
            visibility: visible;
            transform:translateY(0);
          }
        }


      }
    }

    .reveal-sidebar & .right-menu-toggle a {
      @include font-panago-icon(exit);
      background: none;
    }

  }

}


@include header-height(small);

@include breakpoint(medium) {
  @include header-height(medium);

  header.main {
    .logo {
      margin-right: 15px;
      padding-left: 20px;
      padding-right: 15px;
      position: relative;
      a {
        height: map-get($header-heights, medium) - map-get($sub-header-heights, medium);
        width: 200px;
		margin-top: 0;
      }
      &:after {
        // border-right: 1px solid $color-kettle;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        height: 60%;
      }
    }
    .nav {
      padding-right: 15px;
      a {
        font-size: 40px;
      }
    }
    .phone {
      display: block;

      // @include grid-col(4);
    }
    .logo, .nav {
      // @include grid-col-size(4);
    }
  }
}
