ul.rw-list {
  list-style: none;
  margin-left: 0;

  li {
    &:focus {
      outline: none;
    }
    label {
      display: inline-block;
      line-height: 26px;
      input[type=radio] {
        @include checkbox-radio-button;
      }
    }
  }
}
