
section.feature-slides {
  @include grid-row;


  .slide {

    @include breakpoint(xxlarge) {
      // @include grid-col;
    }

    .feature-item {

      .description {
        margin-bottom: 20px;
      }

      .price {
        // margin-bottom: 20px;
      }

      .image, img {
        margin-bottom: 0;
      }

      .meta {
        padding: 30px 20px;

        &.black {
          background-color: rgba(0, 0, 0, 0.85);
        }

        a.button,
        button {
          margin-top: 25px;
        }
      }

      @include breakpoint(medium) {
        img {
          margin-bottom: 0;
        }
        .meta {
          background-color: rgba(255, 255, 255, 0.75);
          border-radius: 4px;
          padding: 20px 30px;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          width: 450px;

          &.black {
            background-color: rgba(0, 0, 0, 0.75);
          }

          &.none {
            background-color: transparent;
          }

          .description {
            margin-bottom: 35px;
          }

          .price {
            display: inline-block;
            // margin-bottom: 0;
          }

          a.button,
          button {
            float: right;
            margin-top: 18px;
          }
        }

      }

      @include breakpoint(large) {
        .meta {
          padding: 30px 40px;
          right: 60px;
          // top: 80px;
          width: 500px;
        }
      }

      @include breakpoint(xlarge) {
        .meta {
          padding: 50px 40px;
          width: 550px;
        }
      }

      @include breakpoint(xxlarge) {
        .meta {
          padding: 70px 50px;
          width: 600px;
        }
      }
    }
  }

  ul.slick-dots,
  nav ol {
    margin: 10px 0 0;
  }

}

section.features {
  margin-bottom: 35px;
  margin-top: 35px;

  &.with-bg {
    background-size: cover;
    background-position: center;
    margin-bottom: 0;

    &.bg-tiled {
      background-size: auto;
    }

    +.with-bg {
      margin-top: 0;
    }

    >.wrap {
      padding-bottom: 35px;
      padding-top: 35px;

      @include breakpoint(medium) {
        padding-bottom: 70px;
        padding-top: 70px;
        h1 {
          margin-bottom: 65px;
        }
      }

    }
  }

  >.wrap {
    @include grid-row;
    // padding-bottom: 0px;
    // padding-top: 36px;
  }


  h1 {
    @include font-opensans('light');
    color: $color-black;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 28px;
  }

  .feature {
    // margin-bottom: 25px;
    position: relative;
    width: 100%;

    .meta .price {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .meta-above-image {
      h3 {
        letter-spacing: -0.5px;
        margin-bottom: 22px;
      }
      .price {
        position: absolute;
        left: 20px;
        margin-top: 20px;
      }
    }
  }

  .feature-item {
    margin-bottom: 50px;

    .image, img {
      border-radius: 4px;
      overflow: hidden; // for border radius;
    }

    .meta {
      .description {
        margin-bottom: 25px;
      }
      p {
        // height: 66px;
        // overflow: hidden;
      }
    }
  }

	a.button {
		background: $color-black;
	}
  &.extra-padding {
    img {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  @include breakpoint(medium) {
    margin-top: 50px;
    margin-bottom: 70px;

    .feature-item {
      margin-bottom: 0;
      .meta {
        .description {
          margin-bottom: 35px;
        }
      }

      &.video {
        .meta .description {
          margin: 70px 0;

          p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;
          }
        }
      }
    }

    &.extra-padding {
      .feature-item {
        h3.heading {
          font-size: 24px;
        }

        .image, img {
          padding-left: 25px;
          padding-right: 25px;
          margin-bottom: 40px;
        }

      }
    }

    &.centered .feature {
      text-align: center;
    }

    &.cols-1-up {
      .feature {
        @include grid-col;
      }
    }

    &.cols-2-up {
      .feature {
        @include grid-col(6);
      }
      &.weighted-left, &.weighted-right {
        .feature-item {
          img {
            display: none;
          }
          .image {
            display: block;
            @include fixed-height-image(200px);
          }
        }
      }
      &.weighted-left .feature {
        &:first-child {
          @include grid-col(8);
        }
        &:last-child {
          @include grid-col(4);
        }
      }
      &.weighted-right .feature {
        &:first-child {
          @include grid-col(4);
        }
        &:last-child {
          @include grid-col(8);
        }
      }
    }


    &.cols-3-up .feature {
      @include grid-col(4);
    }

  }

  @include breakpoint(large) {
    padding: 0 20px;

    &.extra-padding .feature .feature-item {
      padding-left: 25px;
      padding-right: 25px;
    }

    &.cols-2-up {
      &.weighted-left, &.weighted-right {
        .feature-item {
          .image {
            @include fixed-height-image(260px);
          }
        }
      }
    }

  }

  @include breakpoint(xlarge) {
    padding: 0;

    &.cols-2-up {
      &.weighted-left, &.weighted-right {
        .feature-item {
          .image {
            @include fixed-height-image(320px);
          }
        }
      }
    }
  }

  &.deals {
    >.wrap {
      @include grid-layout(1, '.feature', $grid-column-gutter);

    }

    .feature {
      margin-bottom: 50px;
      .feature-item {
        margin-bottom: 0;
        .meta {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include breakpoint(medium) {
      margin-bottom: 0;
      >.wrap {
        @include grid-layout(2, '.feature', $grid-column-gutter);
        .feature {
          margin-bottom: 70px;
		  min-height: 630px;

        }
      }
    }
	a.button {
		background: $color-black;
	}
  }

  &.deals:not(.location) {
    .feature .feature-item a.button {
      display: none; // react is injecting a button for us.
    }
  }



}

section.features, section.feature-slides {
  .feature-item {
    @include clearfix;
    .meta-above-image,
    .meta {
      @include grid-col;
    }
  }

  @include breakpoint(medium) {
    .feature-item {
      .meta-above-image,
      .meta {
        @include grid-column-collapse;
      }
    }
  }


}
