#sidebar-overlay {
  // we want this to be an underlay to main, but overlay to the actual sidebar so it hides when overscrolling
  background-color: white;
  // display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
  transform: translateX(0);   
  transition: transform 300ms ease;

  .reveal-sidebar & {
    background-color: rgba(151,151,151,0.8);
    cursor: pointer;
    display: block;
    transform: translateX(-$sidebar-width);   
    z-index: 9999;
  }
}

nav#sidebar {
  background-color: $color-black;
  box-shadow: inset 2px 0px 4px 0px rgba(0,0,0,0.5);
  color: white;
  font-size: 15px;
  // border-left: 1px solid white;
  transition: transform .3s ease;
  height: 100%;
  overflow: scroll;
  padding: 40px 20px;
  position: fixed;
  right: 0;
  top: 0;
  width: $sidebar-width;
  z-index: 99;
  transform: translateX($sidebar-width);

  .reveal-sidebar & {
    transform: translateX(0);    
  }  

  .sidebar_close {
    position: absolute;
    @include font-panago-icon(exit);
    top: 20px;
    right: 20px;
    color: $color-white;
    font-size: 22px;
  }


  @include breakpoint(medium) {
    padding: 50px 20px;
  }

  ul.menu, ul.sub-menu {
    // font-size: 18px;
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: white;
      display: block;
      line-height: 20px;
      padding: 11px 0;
      font-size: 18px;
      font-weight: 600;
    }

    li.submenu-title {
      >a {
        color: $color-kettle;
        cursor: pointer;
        pointer-events: none;        
      }

      ul.sub-menu {
        margin-bottom: 12px;
      }
    }
  }

  ul.menu {
    >li {
      // border-bottom: 1px solid $color-oven;
    }
  }

  ul.sub-menu {
    padding-left: 15px;
    a {
      padding: 4px 0;
      font-weight: 300;
    }
  }

  // ul

  .social.links {
    margin-top: 60px;

    ul li a {
      color: $color-kettle;

      &:hover {
        color: white;
      }

    }
  }
}

