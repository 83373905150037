// .reveal-sidebar, 
.react-modal-right, 
.react-modal-general, 
.react-modal-primary, 
.react-modal-secondary {
  overflow: hidden;
  // position: fixed;
}

// .reveal-sidebar, 
.react-modal-right, 
.react-modal-general, 
.react-modal-primary,         // Had note here before to not hide for primary. Why? 
.react-modal-secondary {
  main.main {
    // height: auto;
    height: 100%;
    overflow: hidden;
    position: fixed;

  }
}

.reveal-sidebar.react-modal-primary {
  main.main {
    // If the main is scrolled down the page and we slide out while a primary-modal is open, the modal,
    // which is position: fixed jumps up the scroll position. Somehow (not too sure why; didn't investigate)
    // setting height:auto on the main scrolls the content to the top and effectively prevents the modal 
    // from jumping around. 
    // height: auto;
  }
}

.react-modal-primary #main_menu {
  display: none;
}

html,body {
  overflow: auto;
  width: 100%;
  // height: 100%;
}

body main.main {
  width: 100%;
  // height: 100%;
  // overflow-y: auto;
  position: relative;
}
