.reset-password {

  @include grid-row;
  padding-top: 30px;
  padding-bottom: 50px;

  h2 {
    @include grid-col;
    @include font-roboto();
    margin-bottom: 30px;
  }

  form {
    @include grid-col;

    .form-field {
      margin-bottom: 20px;
    }

    .field-actions {
      padding-top: 20px;
      text-align: right;
    }
  }

}