section.image-info {
  @include grid-row;
  margin-top: 20px;
  margin-bottom: 20px;

  .wrap {
    .image, .info {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .covered-image {
    @include ar-container;
    @include covered-image;
  }

  h3 {
    @include font-generic-sans(900);
    line-height: 1em;
    font-size: 32px;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 1.444;
    color: #636362;
  }

  a {
    color: #FC3E23;
    text-decoration: none;
  }

  .info {
    margin-top: 20px;
    margin-bottom: 40px;

    .items {
      &.multiple {
        position: relative;
        height: 466px;
        overflow: auto;

        .info-item {
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.5s ease;
          width: 100%;
          height: 100%;
          padding-bottom: 87px;
          overflow: auto;

          &.active {
            opacity: 1;
            z-index: 1;
          }
        }

        .toggles {
          // @include clearfix;
          background-color: white;
          bottom: 6px; // for active circles
          display: flex;
          height: 87px;
          justify-content: space-between;
          left: 0px;
          max-width: 400px;
          padding: 10px 20px 0;
          position: absolute;
          width: 100%;
          z-index: 2;

          a {
            // background-size: 39px 49px;
            background-position: center bottom;
            background-repeat: no-repeat;
            // background-size: contain;
            display: block;
            // float: left;
            height: 77px;
            width: 100px;
            // margin: 0 10px;
            position: relative;
            margin: 0;

            span {
              position: absolute;
              color: #B8B8B8;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
              font-size: 14px;
              line-height: 18px;
            }

            &.badge {
              background-image: url('../images/ic_quality_rollover.svg');
              background-size: 35px;
              width: 35px;
            }

            &.beef {
              background-image: url('../images/beef.svg');
              background-size: 56px;
              width: 56px;
            }

            &.pork {
              background-image: url('../images/pork.svg');
              background-size: 56px;
              width: 56px;
            }

            &.chicken {
              background-image: url('../images/chicken.svg');
              background-size: 28px;
              width: 28px;
            }

            &.welfare {
              background-image: url('../images/animal-welfare.svg');
              background-size: 29px;
              width: 29px;
            }

            &.active {
              // background-image: url('../images/ic_quality_rollover.svg');
              &:before {
                content: '';
                background-color: rgba(162,178,30,0.25);
                border-radius: 28px;
                width: 56px;
                height: 56px;
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
              }
            }

            &:first-child {
              // margin-left: 0;
            }

            &:last-child:after {
              // display: none;
            }
          }
        }
      }
    }
  }

  @include breakpoint(medium) {
    .wrap {
      display: flex;
      flex-direction: row;
      align-items: center;

      .image, .info {
        flex-basis: 50%;
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .info {
      margin-top: 0;
      margin-bottom: 0;

      .items {
        &.multiple {
          height: 380px;
        }
      }

    }

    .info-item {
      padding-left: 20px;
      padding-right: 20px;
    }

    .toggles {
      left: 30px;
      a {
        // margin: 0 18px;
        width: 115px;
        span {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &.right {
      .wrap {
        flex-direction: row-reverse;
      }
    }
  }

  @include breakpoint(large) {
    margin-top: 60px;
    margin-bottom: 60px;

    padding-left: 85px;
    padding-right: 85px;

    .wrap {
      .image, .info {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .info-item {
      padding-left: 30px;
      padding-right: 30px;
    }

    .info {
      .items {
        &.multiple {
          height: 425px;
        }
      }

    }

  }

  @include breakpoint(xlarge) {
    h3 {
      font-size: 40px;

    }

    p {
      font-size: 20px;
    }


    .info {
      .items {
        &.multiple {
          height: 500px;
        }
      }

    }

  }

}
