.modal-right-content {
  header.modal-header,
  >header {
    @include callout-header('.react-modal-right');

    h3 {

    } 
    
    a {
      &.previous, &.next {
        font-size: 16px;
        position: absolute;
      }

      &.previous {
        right: 85px;
      }

      &.next {
        right: 55px;
      }

      @include breakpoint(medium) {
        &.previous, &.next {
          font-size: 22px;
        }
        
        &.previous {
          right: 120px;
        }

        &.next {
          right: 70px;
        }
      }
    }     
  }


  div.content {
    @include modal-panel(($z-top + 1), $modal-panel-tops, true);
    padding: 30px 20px 50px;
  }


  @include breakpoint(medium) {
    &:before {
      background-color: rgba(0,0,0,0.8);
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: $z-top + 1;
    }


    >header,
    div.content {
      left: 33.3333%;
      width: 66.6667%;
      max-width: 1000px;
    }

    div.content {
      height: 100%;
      padding: 40px 30px 100px;
    }
  }

  @include breakpoint(large) {
    >header,
    div.content {
      left: 50%;
      width: 50%;
    }

  }

  &.loading-section {
    .content {
      overflow: hidden;

      &:before,&:after {
        @include modal-panel(($z-top + 1), $modal-panel-tops, true);
        content: '';
        max-width: 1000px;
        left: 0;
        z-index: $z-top + 2;        
      }
      &:before {
        background-color: rgba(200,200,200,0.8);
      }
      &:after {
        @include spinner;
        // background-size: 80px;
        background-color: transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
      }
      

      @include breakpoint(medium) {
        &:before {
          height: 100%;
          left: 33.3333%;
          width: 66.6667%;
        }
      }

      @include breakpoint(large) {
        &:before {
          left: 50%;
          width: 50%;
        }
      }
    }
  }

}
