.location-choice-panel {
  @include modal-panel($z-top + 6, $callout-tops);
  text-align: center;

  >a {
    color: $color-oven;
    font-size: 28px;
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: $z-top + 7;
  }

  .wrap {
    padding: 50px 20px 30px;
  }

  h2 {
    @include font-opensans('light');
    color: $color-avocado;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  input[type=text] {
    @include font-opensans('light');
    background-color: $color-light;
    border: none;
    border-radius: 4px;
    color: $color-oven;
    display: inline-block;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    width: 380px;

    &:focus {
      outline: none;
    }
  }

  .react-autosuggest__suggestions-container {
    @include react-autocomplete;
    left: 0;
    padding: 0 20px;
    position: absolute;
    z-index: $z-top + 7;
    width: 100%;

    ul {
      min-height: 270px;
      width: 380px;
    }
  }

  p {
    @include font-roboto('light');
    color: $color-oven;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
  }

  div.actions {
    margin: 40px auto 0;

    .button {
      
      display: block;
      margin: 0 auto 10px;
      max-width: 380px;
      // width: 100%;

      &.secondary {
        background-color: $color-oven;
      }

      &.primary {
        background-color: $color-avocado;
      }
    }
  }

  @include breakpoint(medium) {
    >a {
      font-size: 28px;
    }
    .wrap {
      padding: 0;
      position: relative;
      top: 50%;
      transform: translateY(-66.6667%);    
    }

    input[type=text] {
      font-size: 24px;
      height: 80px;
      line-height: 80px;
    }

    div.actions {
      .button {
        display: inline-block;
        margin: 0 5px;
        // width: 160px;
      }
    }
  }
}
