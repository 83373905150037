.topping-volume-picker,
.qty-picker {
  @include clearfix;
  margin-bottom: 12px;
  
  div.rw-numberpicker, .increment-picker {
    // float: left;
  }

  label {
    // float: left;
    display: block;
    line-height: 22px;
    margin: 10px 0;
    text-transform: uppercase;
  }

  @include breakpoint(large) {
    div.rw-numberpicker, .increment-picker, label {
      float: left;
    }
    label {
      float: left;
      margin: 0 0 0 30px;
      width: calc(100% - 190px);
      line-height: 40px;

      span {
        display: inline-block;
        height: 40px;
        line-height: 22px;
      }
    }
  }
}