
#checkout {

  @include floater-button;
  bottom:20px;
  left: calc(100% - 80px - 50px);    // use this instead of right for margin-left on body.reveal-sidebar
  cursor: pointer;

  .cart-footer {
    @include floater-button-icon(cart);
    height: 100%;
    width: 100%;

    &:before {
      margin-left: -2px;    // offset to the left a bit.
    }

    .item-count {
      @include font-roboto;
      background-color: #5CACD0;
      border-radius: 10px;
      bottom: 50%;
      font-size: 12px;
      height: 20px;
      left: 50%;
      line-height: 20px;
      margin: 0 0 -3px -3px;
      position: absolute;
      text-align: center;
      width: 20px;
      z-index: $z-top + 2;
    }

    &.fetching {
      &:before {
        @include spinner;
        background-size: 50%;
        height: 100%;
        left: 0;
        margin-left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .item-count {
        display: none;
      }

    }

  }

}

#comment-bubble {
  @include floater-button(bubble);
  bottom:20px;
  cursor: pointer;
  left: calc(100% - 20px - 50px);
  // right: 20px;
}

@include breakpoint(medium) {
  #checkout {
    left: calc(100% - 150px - 80px);
    // right: 150px;

    .item-count {
      margin: 0;
    }
  }
  #comment-bubble {
    left: calc(100% - 60px - 80px);
    // right: 60px;
  }

  #checkout, #comment-bubble {
    bottom: 30px;
  }

}
