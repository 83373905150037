
// selector for now as markup isn't ideal
.menu-centered >.row {
  div.callout {
    // this is the category header
    background-color: $color-cayenne;
  }
  >div.row {
    >div.columns.small-12.large-6 > div {
      @include clearfix;
      border-bottom: 1px solid $color-light;
      text-align: left;
      margin-bottom: 45px;
      padding-bottom: 14px;

      h4 {
        @include font-roboto('normal', 'slab');
        color: $color-cayenne;
        font-size: 24px;
        line-height: 1.2em;
        margin-bottom: 18px;
      }

      >a {
        @include button-normal;
        float: right;
      }
    }
  }
}