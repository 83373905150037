// COMPONENTS
%icon-headers {
  h4 {
    margin-left: 70px;
    position: relative;

    &:before {
      @include font-panago;
      color: #444;
      font-size: 54px;
      left: -70px;
      position: absolute;
      top: -10px;
    }
  }

  .choices {
    padding-left: 70px;
  }
}
$appClasses: (
  pizza_cheeses: cheese,
  pizza_sauces: sauce,
  pizza_meats: protein,
  pizza_veggies: veggies,
  pizza_other_toppings: toppings,
  salad_cheeses: cheese,
  salad_proteins: protein,
  salad_toppings: toppings,
  wingdip: wingdip,
  breaddip: breaddip,
);

// TODO break this into mixin.
.collapsable,
.choice-group,
.customize-panel {
  @include font-roboto('light');
  color: $color-oven;
  font-size: 16px;

  >h4 {
    @include font-opensans('light');
    border-bottom: 1px solid $color-light;
    // color: $color-cayenne;
    font-size: 20px;
    line-height: 25px;

    margin-bottom: 22px;
    padding-bottom: 22px;
    // @include template-colors(color);
	color: #444;

    p.deal-slot-selection {
      font-size: 0.75em;
      color: $color-charcoal;
      margin: 0.5em 0 0;
      line-height: 1;
    }

    @include breakpoint(medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.customize-panel,
.collapsable {
  >h4 {
    cursor: pointer;
    padding-right: 30px;
    position: relative;
    &:after {
      @include font-panago;
      @include font-panago-pseudo-icon(minimize);

      height: 30px;
      line-height: 30px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 30px;
      // transition: transform 0.3s;
      // transform: rotate(0deg);
    }

  }
  &.collapsed >h4:after {
    // transform: rotate(180deg);
    @include font-panago-pseudo-icon(expand);
  }
}

.customize-panel {
  margin-top: 40px;
  > h4 {
    text-transform: uppercase;

    &:after {
      @include font-panago-pseudo-icon(exit);
    }
  }

  div.collapsable {
    margin-bottom: 40px;
  }
}


.collapsable {
  >h4 {

  }

  h4.on-sale span {
    display: inline-block;
    position: relative;
    @include font-roboto('medium');
    @include price-salebug;
  }

  h4 sup {
    font-size: 12px;
  }

  // This is for a specific page - where?
  .choices {
    @include clearfix;
    .memo {
      position: relative;
      &:before {
        content: '•';
        color: $color-cayenne;
        font-size: 22px;
        position: absolute;

        left: -10px;
        z-index: $z-top;
      }
    }
    p {
      @include font-roboto('light');
      color: $color-kettle;
      font-size: 16px;
      line-height: 30px;
      margin: 0;

      span.memo {
        padding-left: 10px;
        &:before {
          left: 0;
        }
      }
    }

    >p {
      margin-bottom: 1em;
    }


    // Doesnt' really belong in here.
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }


  @each $className, $name in $appClasses {

    &.#{$className} {
      @extend %icon-headers;
      h4:before {
       @include font-panago-pseudo-icon($name);
      }
    }
  }
}

// special case for dips on wings/desserts/breads page
ul.products.dessert, ul.products.breadstuff {
  .collapsable.dips {
    @extend %icon-headers;
    h4:before {
      @include font-panago-pseudo-icon(breaddip);
    }
  }
}
ul.products.wings {
  .collapsable.dips {
    @extend %icon-headers;
    h4:before {
      @include font-panago-pseudo-icon(wingdip);
    }
  }
}
