$roboto-fonts: (
  'normal': 'Roboto',
  'slab': 'Roboto Slab',
  'condensed': 'Roboto Condensed'
);
$font-weights: (
  'light': 300,
  'normal': 400,
  'medium': 400,
  'bold': 700,
  'black': 900
);

$updated-fonts: (
	'normal': 'neuzeit-grotesk',
	'slab': 'neuzeit-grotesk',
	'condensed': 'neuzeit-grotesk'
);
//font-family: 'Montserrat', sans-serif;

@mixin font-generic-sans($weight: 700) {
  font-family: 'neuzeit-grotesk', sans-serif;
  font-weight: $weight;
}

@mixin font-roboto($weight: 'normal', $type: 'normal') {
  font-weight: map-get($font-weights, $weight);
  font-family: map-get($updated-fonts, $type), sans-serif;
}

@mixin font-opensans($weight) {
  font-family: 'neuzeit-grotesk', sans-serif;
  font-weight: map-get($font-weights, $weight);
}

@mixin font-anton() {
  font-family: 'Anton', sans-serif;
  font-weight: 400;
}

h2.heading {
  @include font-roboto('bold', 'condensed');
  color: $color-black;
  font-size: 32px;
  line-height: 0.85em;
  margin-bottom: 0.5em;
  text-transform: uppercase;

  em {
    font-style: normal;
  }

  @include breakpoint(medium) {
    font-size: 40px;
    letter-spacing: -2px;
  }

  @include breakpoint(large) {
    font-size: 60px;
    margin-bottom: 0.3333em;
  }


}

h3.heading {
  @include font-roboto('bold', 'condensed');
  color: $color-kettle;
  font-size: 24px;
  line-height: 1.03;
  margin-bottom: 0.6667em;
  // text-transform: uppercase;

  em {
    color: $color-black;
    font-style: normal;
  }

  @include breakpoint(medium) {
    font-size: 28px;
  }

}

body {
  @include font-roboto('light');
}

p {
  line-height: 1.375;
  font-weight: 18px;
	word-spacing: -0.15em;
	line-height: 1.4em;
}
h1, h2, h3, h4 {
	@include font-roboto('black');
	word-spacing: -0.15em;
}
h1 {
	font-size: 44px;
}
h2 {
	font-size: 60px;
}
h3 {
	font-size: 44px;
}
h4 {
	font-size: 26px;
}
a {
  transition: color 0.2s linear;
}

@mixin button-normal($color: $color-oven) {
  @include font-roboto('black');
  background-color: $color;
  border: none;
  border-radius: 3px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  width: 140px;
  border-radius: 20px;

  &:disabled, &.disabled, &.active.disabled, &.active:disabled {
    background-color: $color-gravel !important;
    cursor: default !important;
  }


}


button, a.button, input.button {
  @include button-normal;

}
