#general_modal {
  
  #frame_modal {
    .active-frame {
      .frame-container {
        border-radius: 0;
        height: auto;
        margin: 0 15px;
        padding: 15px 15px 25px;
        top: 60px;
        width: calc(100% - 30px);

        .modal-content {
          h1 {
            @include font-opensans('light');
            color: $color-avocado;
            font-size: 1.25rem;
            text-transform: uppercase;
          }

          p {
            font-size: 0.875rem;
          }

          form {
            input {
              border: none;
              border-bottom: 1px solid $color-light;
              font-size: 0.875rem;
              padding: 5px 0;
              width: 100%;
            }

            hr {
              display: none;
            }

            .modal-actions {
              @include clearfix;
              margin-top: 15px;
              
              button, a.button.primary {
                background-color: $color-aquamarine;
                float: right;
              }

              .button {
                width: auto;
                padding: 0 11px;
              }

              a.button, a.button.cancel {
                float: right;
                width: auto;
              }

              a.button.cancel {
                margin-right: 13px;
              }

              @include breakpoint(medium) {
                .button {
                  padding: 0 15px;
                }

                a.button.cancel {
                  margin-right: 15px;
                }
              }
            }
          }
        }

        @include breakpoint(medium) {
          left: 50%;
          margin: 0;
          top: 50%;
          width: 400px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}