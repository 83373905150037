.social.links {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      a {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
}

.input-force-uppercase {
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}

.standard-page .entry-content .read-more-content {
  padding: 1px 0;   // to fix weird margin bug.

  a.toggler {
    color: #000;
    display: inline-block;
    margin: 20px 0;
  }

  .inner-content {
    display: none;
  }
}
