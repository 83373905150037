
.category-preview {

  a {
    display: block;
	p {
		display: none;
		// color: #000;
		//   font-size: 18px;
		//   line-height: 22px;
	}
  }

  h3 {
    @include font-opensans('bold');
    background-color: none;
    color: #000;
    font-size: 22px;
    height: 70px;
    line-height: 60px;
    margin: 0;
    padding: 0px;
    text-align: left;

    &:after {
      // @include font-panago;
      // @include font-panago-pseudo-icon('expand');
      // float: right;
      // line-height: 60px;
    }

    &.active {

    }

    a {
      color: inherit;
      &:hover {

      }

    }

    @include breakpoint(large) {
      font-size: 44px;
	  height: 70px;
	  line-height: 90px;

      &:after {
        line-height: 100px;
      }
    }
  }
}

@include root-template-colors(color, 'h3:after', '.category-preview');
