// Glyphicons font path
$icon-font-path:        "../fonts/";

// to max out the body container, trying making body max-width 2000
$global-width: 1220px;//rem-calc(2000);

$breakpoints: (
  small: 0,
  medium: 740px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1200px,
  xxxlarge: 1300px,
);

$grid-column-gutter: (
  small: 40px,
  medium: 30px,
  large: 30px,
  xlarge: 60px
);

$breakpoint-classes: (small medium large xlarge xxlarge);
// Foundation Breakpoints

$grid-column-align-edge: false;

$header-heights: (
  small: 90px,
  medium: 110px //60px
);

$sub-header-heights: (
  small: 29px,
  medium: 29px
);

$menu-header-heights: (
  small: 130px,
  medium: 160px
);

$callout-tops: (
  small: map-get($header-heights, small),
  medium: map-get($header-heights, medium)
);
$callout-heights: (
  small: 50px,
  medium: 100px
);
$modal-panel-tops: (
  small:  map-get($header-heights, small) +  map-get($callout-heights, small), //100px,
  medium: map-get($header-heights, medium) +  map-get($callout-heights, medium)  //160px
);

$menu-padding: (
  small: 26px,
  medium: 30px
);

$menu-icon-size: (
  small: 80px,
  medium: 100px
);

$z-top: 10;

$z-header: $z-top + 6;

$sidebar-width: 280px;
