#page-404 {
  @include grid-row;

  section {
    @include grid-col;    

    h2 {
      color: black;
    }

    .link-options {
      .widget {
        margin: 30px 0;

      }
      ul {
        background: none;
        list-style: none;
        padding: 0;

        li {
          margin: 5px 0;
        }
      }
    }
  }
}