#frame_modal {

  .active-frame {
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    overflow: hidden;
    z-index: $z-header - 1;


    .frame-container {
      background-color: white;

      position: fixed;
      top: 50px;
      height: calc(100% - 50px);
      width: 100%;
      padding: 50px 20px 20px;
      z-index: $z-header;

      a.close {
        @include font-panago-icon(exit);
        background-color: white;
        border-radius: 60px;
        color: black;
        font-size: 40px;
        height: 50px;
        line-height: 50px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 0;
        width: 50px;
      }

      .iframe-wrap {
        height: 100%;
      }

      .browsing-ios & .iframe-wrap {
        overflow: auto;
        -webkit-overflow-scrolling: touch;        
      }

      iframe {
        border: 0;
        height: 100%;
        width: 100%;
      }

      @include breakpoint(medium) {
        border-radius: 20px;
        height: calc(90% - 120px);
        left: 10%;
        padding: 50px;
        top: 120px;
        width: 80%;

        a.close {
          right: -15px;
          top: -15px;
        height: 60px;
        line-height: 60px;
        width: 60px;
        }
      }

    }

  }
}