section.image-grid {
  background-color: #EFEFEF;
  padding: 50px 0;

  .wrap {
    @include grid-row;
  }

  h3 {
    @include font-generic-sans(900);
    font-size: 32px;
    line-height: 1em;
    margin-bottom: 20px;
  }

  p {
    color: #636362;
    font-size: 16px; 
    line-height: 1.4444em;
  }

  h3, p {
    @include grid-col;
    text-align: center;
  }

  ul {
    @include clearfix;
    clear: both;
    list-style: none;
    margin: 0;
    padding-top: 20px;

    li {
      @include grid-col;
      margin-bottom: 30px;

      a {
        @include ar-container(346, 289);
        @include covered-image;
        display: block;

        label {
          @include font-generic-sans(900);
          color: white;
          position: absolute;
          width: 100%;
          padding: 0 30px;
          top: 50%;
          text-align: center;
          transform: translateY(-50%);
          font-size: 32px;
          line-height: 1em;
        }
      }
    }
  }

  @include breakpoint(medium) {
    padding: 75px 0;

    h3 {
      font-size: 40px;
    }

    p {
      font-size: 18px;
    }

    h3, p {
      @include grid-col-size(8);
      @include grid-column-position(center);
    }

    ul {
      padding-top: 40px;

      li {
        @include grid-col-size(4);
        margin-bottom: 0;

        label {
          font-size: 38px;
        }
      }
    }
  }
}