.feature-item {
  display: block;
  position: relative;

  h3.heading {
    @include font-generic-sans(900);

    @include breakpoint(medium) {
      font-size: 33px;
    }
  }

  .link-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .price {
    color: $color-black;
    font-size: 7px;
    padding-left: 3em;
    position: relative;

    @include breakpoint(medium) {
      font-size: 8px;
    }

    @include breakpoint(large) {
      font-size: 10px;
    }

    .amount {
      // @include font-anton;
      @include font-generic-sans(800);
      display: block;
      font-size: 6em;
      line-height: 1.1;
      position: relative;

      &:before {
        // @include font-roboto('bold');
        content: '$';
        position: absolute;
        left: (-2.8em / 4);
        font-size: (4em / 6);
        top: (0.8em / 4);
      }

      .on-sale {
        position: relative;
        @include price-salebug;
        &:after {
          right: -55px;
          top: (2em / 6);          
        }
      }
    }

    .label {
      @include font-roboto('medium');
      display: block;
      font-size: 1.4em;
      line-height: 1.023;
      text-transform: uppercase;

      .feature-slides & {
        @include font-roboto('normal');
        font-size: 14px;
        line-height: 22px;
      }

    }
  }

  button {
    float: right;
    // width: 100%;

    @include breakpoint(medium) {
      float: none;
      width: 140px;
    }
  }


  .image, img {
    margin-bottom: 25px;
  }

  img {
    height: auto;
    width: 100%;    
  }

  .image {
    display: none;
  }

  &.video {
    img, .image {
      display: none;
    }
    @include breakpoint(medium) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(xlarge) {
      max-width: 53%;
    }

    .vjs-big-play-button {
      // @include font-panago;
      background: none;
      background-image: url('../images/play-button.svg');
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      font-size: 80px;
      height: 80px;
      line-height: 80px;
      margin-left: -40px;
      margin-top: -40px;
      padding: 0;
      text-align: center;
      width: 80px;

      &:before {
        content: '';
        // @include font-panago-pseudo-icon(chevron-right);
      }
    }
    .vjs-poster {
      background-size: cover;
    }
    .vjs-has-started.vjs-paused .vjs-big-play-button {
      display: block;
    }
    .vjs-has-started .vjs-control-bar {
      display: none;
    }
  }


}