section.cart {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      header {
        @include clearfix;
        span {
          @include font-roboto('light');
          color: $color-charcoal;
          display: block;
          float: left;
          font-size: 18px;
          line-height: 30px;
          &.qty {
            width: 30px;
          }
          &.name {
            width: calc(70% - 30px);
          }
          &.price {
            text-align: right;
            width: 30%;
            @include breakpoint(xlarge) {
              width: 20%;
            }
          }
        }
      }
      .description {
        @include font-roboto('light');
        color: $color-kettle;
        font-size: 14px;
        line-height: 20px;
        padding-left: 30px;
        p {
          margin: 10px 0;
          &:last-child {
            //margin-bottom: 30px;
          }
        }
      }
      .actions {
        margin-top: 20px;
        padding-left: 30px;

        a.button {
          background: none;
          // color: $color-kettle;
          color: #000;
          font-size: 14px;
          line-height: 20px;
          width: auto;

          &:last-child {
            color: #E94F35;
          }
        }
      }
    }
  }
}

section.cart-totals {
  border-bottom: 1px solid $color-light;
  border-top: 1px solid $color-light;
  margin-bottom: 30px;
  margin-top: 15px;
  padding-bottom: 20px;
  padding-top: 20px;

  dl {
    @include pricing-totals;
  }
}

section.cart-actions {

}
