$panago-font-path: "../fonts" !default;

$font-glyphs: (
  bars: "\e900",
  breaddip: "\e901",
  breads: "\e902",
  bubble: "\e903",
  cart: "\e904",
  cheese: "\e905",
  deals: "\e906",
  desserts: "\e907",
  drinks: "\e908",
  exit: "\e909",
  expand: "\e90a",
  facebook: "\e90b",
  instagram: "\e90c",
  location: "\e90d",
  // locationfill-path1: "\e90e",
  // locationfill-path2: "\e90f",
  // locationfill-path3: "\e910",
  minimize: "\e916",
  minus: "\e912",
  nutrition: "\e913",
  pizza: "\e917",
  plus: "\e918",
  profile: "\e919",
  // profilefill-path1: "\e91a",
  // profilefill-path2: "\e91b",
  // profilefill-path3: "\e91c",
  // profilefill-path4: "\e91d",
  protein: "\e91e",
  salads: "\e91f",
  sauce: "\e920",
  twitter: "\e921",
  veggies: "\e922",
  wingdip: "\e923",
  wings: "\e924",
  sides: "\e924",
  toppings: "\e914",

  chevron-up: "\e916",
  chevron-down: "\e911",
  chevron-left: "\e925",
  chevron-right: "\e915",

  spinner: "\e97a",

  share: "\e926"
);

@font-face {
  font-family: 'panagoweb';
  src:  url('#{$panago-font-path}/panagoweb.eot?3245vi');
  src:  url('#{$panago-font-path}/panagoweb.eot?3245vi#iefix') format('embedded-opentype'),
    url('#{$panago-font-path}/panagoweb.ttf?3245vi') format('truetype'),
    url('#{$panago-font-path}/panagoweb.woff?3245vi') format('woff'),
    url('#{$panago-font-path}/panagoweb.svg?3245vi#panago') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin font-panago() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'panagoweb' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="panago-icon"] {
  @include font-panago;
}

@mixin font-panago-pseudo-icon($glyph:'') {
  content: map-get($font-glyphs, $glyph);
}

@mixin font-panago-icon($glyph:'') {
  @include font-panago;
  &:before {
    content: map-get($font-glyphs, $glyph);
  }
}

@each $name, $glyph in $font-glyphs {
  .panago-icon-#{$name}:before {
    content: $glyph;
  }
}
