.unslider{overflow:auto;margin:0;padding:0}
.unslider-wrap{position:relative}
.unslider-wrap.unslider-carousel>li{float:left}
.unslider-vertical>ul{height:100%}
.unslider-vertical li{float:none;width:100%}
.unslider-fade{position:relative}
.unslider-fade .unslider-wrap li{position:absolute;left:0;top:0;right:0;z-index:8}
.unslider-fade .unslider-wrap li.unslider-active{z-index:10}
.unslider-arrow{position:absolute;left:20px;z-index:2;cursor:pointer}
.unslider-arrow.next{left:auto;right:20px}


.slider-component li,.slider-component ol,.slider-component ul{list-style:none;margin:0;padding:0;border:none}

.feature-slides {
  &.loading {
    .slide {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}



ul.slick-dots,
.unslider-nav ol {
  list-style: none;
  text-align: center;

}
ul.slick-dots li {
  display: inline-block;
}
ul.slick-dots li button,
.unslider-nav ol li {
  background-color: $color-light;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: inline;
  height: 10px;
  margin: 0 5px;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: middle;
  width: 10px;


}
ul.slick-dots li.slick-active button,
.unslider-nav ol li.unslider-active {
  background-color: $color-kettle;
  cursor: default;
}
