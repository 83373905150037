@keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}

@keyframes slidedown {
  0%   { transform: translateY(-100%)}
  100% { transform: translateY(0%)}
}
@keyframes slideup {
  0%   { transform: translateY(0%)}
  100% { transform: translateY(-100%)}
}