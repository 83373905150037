.store-locations {
  // see also _checkout.scss

  @include grid-row;

  h1 {
    @include grid-col;
    @include font-opensans('light');
    $h: map-get($callout-heights, small);  

    background-color: $color-lighter;
    color: $color-oven;
    float: left;
    font-size: 20px;
    height: $h;
    line-height: $h;
    margin: 0;
  }

  .form {
    @include grid-col;
    padding-bottom: 15px;
    padding-top: 15px;

    input {
      margin-bottom: 0;
    }
  }

  .map-view {
    clear: both;
    height: 300px !important;
  }

  ul {
    @include grid-row;
    @include grid-layout(1, 'li', $grid-column-gutter);
    margin-top: 40px;
    li {
      border: none;
      padding-bottom: 0;
      margin: 30px 0;
      >div {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint(medium) {
    h1 {
      @include grid-col(6);
      $h: map-get($callout-heights, medium);  
      font-size: 32px;
      height: $h;
      line-height: $h;      
    }
    .form {
      @include grid-col(6);
      padding-bottom: 25px;
      padding-top: 25px;
    }
    .map-view {
      height: 400px !important;
    }

    ul {
      @include grid-layout(2, 'li', $grid-column-gutter);
    }
  }
}