.home, .page-id-2712 {
	> main {
	  padding-top: calc(225px + var(--simple-banner-height));
	}
	// @media print, screen and (min-width: 46.25em) {
	//   > main {
	//     padding-top: calc(55px + var(--simple-banner-height));
	//   }
	// }
	.features {
		.feature-item {
			img {
				border-radius: 0;
			}
		}
	}
	.features.cols-1-up {
		margin: 0;
		padding: 0;
		.wrap {
			padding: 0;
		}
		img {
			margin-bottom: 0;
		}
		.meta {
			display: none;
		}
		.feature {
			padding: 0;
		}
	}
	.features.cols-3-up {
		margin-top: 0;
		margin-bottom: 0;
		.wrap {
			background: #411557;
			padding-bottom: 200px;
		}
		h1 {
			margin: 150px auto 90px;
			font-size: 60px;
			font-weight: 900;
		}
		.feature {
			padding-right: 15px;
			padding-left: 15px;
		}
		.feature-item {
			background: #fff;
			h3.heading {
				display: none;
			}
			img {
				border-radius:0;
				margin-bottom: 0;
			}
			p {
				font-size: 18px;
				line-height: 22px;
			}
			.meta {
				padding: 1.875rem;

			}
		}
	}
	.menu-link-wrapper {
		@include grid-row;

		h1  {
			text-align: center;
			margin: 120px auto 60px;
		}
		ul.menu {
		  @include clearfix;
		  display: block;
		  list-style: none;
		  margin: 0 auto;
		  max-width: 700px;
		  padding-top: 15px;
		  margin-bottom: 200px;
			@media all and (max-width: 800px) {
				margin-bottom: 100px;
			}

		  li {
			display: inline-block;
		  padding: 0 20px;
			vertical-align: middle;
			margin-bottom: 20px;
			a {
			  @include font-roboto('bold');
			  color: #000;
			  display: block;
			font-size: 20px;
			width: 100px;
			  line-height: 1;
			  margin: 0 10px;
			  padding: 110px 0 0;
			  position: relative;
			  text-align: center;
			  text-transform: capitalize;

			  &:before {
				@include font-panago;
				background-color: $color-transparent;
				background-image: url('../images/home-svg-icons.png');
				background-repeat: no-repeat;
				border-radius: 50px;
				color: $color-purple;
				content: '';
				font-size: 48px;
				display: inline-block;
				left: 0;
				position: absolute;
				top: 0;
				height: 100px;
				line-height: 100px;
				width: 100px;
				transition: color 0.2s linear, background-color 0.2s linear;

				@media  (-webkit-min-device-pixel-ratio: 2),
				(min-resolution: 192dpi) {
					background-image: url('../images/home-svg-icons@2x.png');

				}
			  }

			  &.active, &:hover {
				color: $color-purple;

				&:before {
				  background-color: $color-purple;
				  color: $color-mint;
				}
			  }

			  &.wings {
				white-space: nowrap;
				text-indent: -9px;
				&:before {
				  text-indent: 0;
				}
			  }

			  $markupMap: (
				pizza: pizzas,
				deals: deals,
				salads: salads,
				wings: wings,
				breads: breadstuff,
				desserts: dessert,
				drinks: beverages,
				breaddip: dips
			  );

			  @each $name, $className in $markupMap {
				&.#{$className}:before {
				  @include font-panago-pseudo-icon($name);
				}
			  }

			&.deals {
				&:before {
					content: '';

					background-position: 25px 120%;
				}
				&:hover, &.active {
					&:before {
						background-position: 25px -18%;
					}
				}
			}
			&.pizzas {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -112px 119%;
				}
				&:hover, &.active {
					&:before {
						background-position:-108px -17%;
					}
				}
			}
			&.salads {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -245px 120%;
				}
				&:hover, &.active {
					&:before {
						background-position: -245px -15%;
					}
				}
			}
			&.wings {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -378px 117%;
				}
				&:hover, &.active {
					&:before {
						background-position: -376px -17%;
					}
				}
			}
			&.breadstuff {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -517px 117%;
				}
				&:hover, &.active {
					&:before {
						background-position: -517px -20%;
					}
				}
			}
			&.dessert {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -655px 117%;
				}
				&:hover, &.active {
					&:before {
						background-position: -655px -20%;
					}
				}
			}
			&.beverages {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -795px 118%;
				}
				&:hover, &.active {
					&:before {
						background-position: -795px -20%;
					}
				}
			}
			&.dips {
				&:before {
					content: '';
					// background-image: url('../images/svg-menu.png');
					// background-repeat: no-repeat;
					background-position: -950px 117%;
				}
				&:hover, &.active {
					&:before {
						background-position: -950px -20%;
					}
				}
			}
			}
		  }
		}

		@include breakpoint(medium) {

		  ul.menu {
			padding-top: 18px;
			li a {
			  // font-size: 16px;
			  margin: 0 14px 50px;

			  &:before {
				font-size: 60px;
			  }

			  &.wings {
				text-indent: -1px;
			  }
			}
		  }
		}
	}
}
.our-food-callout-wrapper {
	@include grid-row;

	background-image: url('../images/panago-home.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 100px 1.875rem 80px;
	min-height: 720px;
	display: flex;
	align-items: center;

	.callout-text {
		max-width: 540px;
	}
	h1 {
		font-size: 110px;
		font-weight: 900;
		color: #FEFEFE;
		text-align: left;
		line-height: 88px;
		position: relative;
		left: -5px;

		&:after {
			content: "";
			border-bottom: 15px solid #fff;
			display: block;
			width: 60px;
			margin: 70px 0 30px 5px;
		}
	}
	p {
		font-size: 26px;
		color: #444444;
		letter-spacing: 0;
		line-height: 30px;
		// font-weight: 700;

		a {
			background: #fff;
			border-radius: 27.5px;
			font-size: 22px;
			color: #000000;
			letter-spacing: 0;
			text-align: center;
			line-height: 48px;
			width: 160px;
			height: 50px;
			margin-top: 115px;
		}
	}

}
