// this is general form stuff, but in the modal right

.modal-right-content {
  div.content {
    a.button, button[type=submit], input[type=submit] {
      background-color: $color-oven;
      width: 135px;

      // &.disabled, &.active.disabled {
      //   background-color: $color-gravel;
      //   cursor: default;
      // }

      &.active {
        background-color: $color-aquamarine;
      }

      &:first-child {
        margin-right: 10px;
      }
    }

    .loading-section {
      height: 70px;
      margin-bottom: 30px;
      max-width: 375px;
      position: relative;

      &.map-view {
        background-color: $color-lighter;
        height: 300px;
        margin-bottom: 0;
        max-width: 100%;
      }

      &:before {
        @include spinner;
        background-size: 50px;
        height: 100%;
        left: 0;
        margin-left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    @include breakpoint(medium) {
      a.button, button[type=submit], input[type=submit] {
        width: 150px;

        &:first-child {
          margin-right: 15px;
        }
      }
      .form {
        .form-field {
          input {
            max-width: 375px;
          }
          span.error-message {
            max-width: 375px;
          }
        }
      }
    }

    @include breakpoint(large) {
      a.button, button[type=submit], input[type=submit] {
        min-width: 180px;
        width: 180px;
      }
    }
  }
}

    // This used to be within the block above (.modal-right-content div.content), but moved out
    // so that form styles work for store-locator.
    .form {

      .field-group {
        @include clearfix;

        &.inputs, &.dropdowns, &.autosuggest {
          margin-left: -10px;
          margin-right: -10px;
          .form-field {
            padding: 0 10px;


            span.error-message {
              padding: 0 10px;
              max-width: 100%;
            }
          }
        }

        &.inputs, &.dropdowns {
          .form-field {
            @include breakpoint(medium) {
              float: left;
              position: relative;
              width: 50%;

            }
          }
        }

        &.inputs, &.autosuggest {
          input {
            max-width: 100%;
          }

          &.wide {
            .form-field {
              width: 100%;

              span.error-message,
              input {
                max-width: 100%;
              }
            }

          }
        }

        &.inputs {
          input::placeholder {
            color: white;
          }
        }

        &.inputs.no-label, &.dropdowns.no-label {
         .form-field {
            >label {
              display: none;
            }

            input {
              padding-left: 0;

              &::placeholder {
                color: inherit;
              }
            }
          }
        }

        &.inputs + .dropdowns {
          margin-top: 40px;
        }

        &.dropdowns {

          >label {
            display: none;
          }

          .pretty-select {
            margin-bottom: 18px;
            width: 100%;

          }
          .form-field {
            margin-bottom: 10px;

            @include breakpoint(medium) {
              margin-bottom: 20px;
            }

            // span.error-message {
            //   padding-right: 60px;
            // }

          }
        }
        .form-field {

          > label {
            display: inline;
            position: absolute;
            line-height: 26px;
            padding: 8px 0;
            &:after {
              content: ':';
            }
          }

          input {
            padding-left: 135px;
          }
        }

        .form-actions {
          margin-top: 20px;
        }

      }

      .form-field {
        position: relative;

        >label {
          display: none; // for now.
        }

        input {
          @include underline-input;
        }

        // the error message for now.
        span.error-message {
          // bottom: 0;
          top: 42px;
          color: #E94F35;
          font-size: 12px;
          left: 0;
          margin: 0;
          max-width: 280px;
          position: absolute;
          text-align: right;
          width: 100%;
          z-index: 1;
        }
      }

      .field-actions {
        margin-top: 20px;
      }

      .account-links {
        color: $color-gravel;
        margin: 25px 0;

        a:first-child {
          color: #E94F35;
        }

        a:last-child {
          color: $color-avocado;
          display: inline-block;
          margin-left: 5px;
        }
      }

      @include breakpoint(medium) {
        .form-field {
          input {
            max-width: 375px;
          }
          span.error-message {
            max-width: 375px;
          }
        }
      }
    }


.pretty-select {
  display: inline-block;
  height: 40px;
  max-width: 100%;
  position: relative;
  width: 320px;

  label {
    @include font-roboto('light');
    background-color: $color-lighter;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: $color-oven;
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 30px 0 10px;

    &:after {
      @include font-panago;
      @include font-panago-pseudo-icon(chevron-down);
      background-color: $color-gravel;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: $color-kettle;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 40px;
    }
  }

  select {
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

}
