footer {
  background-color: black;
  color: $color-white;
  padding-top: 75px;
  padding-bottom: 40px;

  a {
    color: $color-white;
  }

  .wrap {
    @include grid-row;
  }

  .logo {
    margin-bottom: 40px;
    a {
      background-image: url('../images/logo_444x82.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      height: 33px;
      text-indent: -99999px;
      width: 180px;
      vertical-align: middle;
    }
  }

  .widget {
    margin-bottom: 40px;
  }

  a, p {
    @include font-roboto('medium');
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  ul, ul.menu {
    list-style: none;
    margin: 0;

    li {
      display: block;
      a {
        &:hover {
          color: $color-gravel;
        }
      }
    }
  }

  .logo {
    @include grid-col;
  }

  .social {
    p {
      margin-bottom: 15px;
    }
  }


  .footer-menu, .footer-menu, .social {
    @include grid-col;
  }

  // .footer-menu.first {
  //   @include grid-col-off(3);
  // }

  @include breakpoint(medium) {
    .footer-menu, .footer-menu, .social {
      @include grid-col-size(4);
    }
  }

  @include breakpoint(large) {
    .footer-menu, .footer-menu, .social {
      @include grid-col-size(3);
    }

    .social {
      @include grid-col-off(3);
    }

  }

}
