.wpcf7-form {
  br {
    display: none;
  }


  .standard-page .entry-content & p,
  p {
    margin: 0;
  }

  .pretty-select {
    .wpcf7-form-control-wrap {
      position: static;
    }
  }

  .input-item.file {
    span {
      display: block;
      position: relative;
      height: 40px;

      margin-top: 0.5em;

      &:before {
        @include button-normal;
        content: 'Choose File';
        position: absolute;
        left: 0;
      }

      &:after {
        height: 40px;
        line-height: 40px;
        left: 150px;
        position: absolute;
        content: attr(filename);
        width: calc(100% - 150px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input[type=file] {
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        width: 140px;
        position: absolute;
        left: 0;
        opacity: 0;
      }
    }
  }
}

#primary_contact_form {
  @include clearfix;
  margin: 20px -15px 0;
  max-width: 675px;

  .input-item {
    @include grid-col;
    margin-bottom: 20px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    input[type=text],input[type=tel],input[type=email], textarea {
      @include font-roboto('light');
      background-color: $color-lighter;
      border-radius: 3px;
      border: none;
      color: $color-oven;
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      width: 100%;
    }

    textarea {
      line-height: 1.5em;
      height: 200px;
      padding: 10px;
    }

    @include breakpoint(medium) {
      @include grid-col-size(6);
      &.address, &.message, &.checkboxes, &.file {
        @include grid-col-size(12);
      }
    }
  }

  .pretty-select {
    height: 50px;
    width: 100%;

    label {
      height: 50px;
      line-height: 50px;
      &:after {
        height: 50px;
        line-height: 50px;
      }
    }

  }

  .wpcf7-list-item {
    margin: 0;
  }

  .checkboxes {
    label {
      display: inline-block;
      line-height: 26px;

      input[type=checkbox] {
        @include checkbox-radio-button;

        &:checked + span {
          color: $color-oven;
        }
      }


    }
  }

  span[role=alert] {
    @include font-roboto('light');
    color: #E94F35;
    padding: 5px 10px;
  }

  .button {
    background-color: $color-avocado;
  }


  +div.wpcf7-response-output {
    margin: 0;
    max-width: 648px;

    &.wpcf7-validation-errors {
      border-color: #E94F35;
    }

    &.wpcf7-mail-sent-ok {
     border-color: $color-avocado;
    }

  }
}
