.modal-right-content {
  .react-autosuggest__container {

    .react-autosuggest__suggestions-container {
      @include react-autocomplete;
      position: relative;
      z-index: $z-top + 7;

      ul {
        position: absolute;
        width: 100%;
      }

      // margin-left: 100px;

      // ul.react-autosuggest__suggestions-list {
      //   background-color: $color-lighter;
      //   padding: 10px 0 0 100px;
      //   margin: 0;
      //   list-style: none;
      //   li {
      //     border: none;
      //     cursor: pointer;
      //     padding: 5px 20px;
      //     &:hover {
      //       text-decoration: underline;
      //     }
      //   }
      // }
    }
  }

  .loading .react-autosuggest__container {
    &:after {
      @include spinner;
      position: absolute;
      top: 10px;
      opacity: 0.5;
      right: 15px;
      height: 22px;
      width: 22px;
    }
  }
}


/*
      .react-autosuggest__suggestions-container {
        @include react-autocomplete;
      }


*/