//$color-cayenne: #E94F35;
$color-cayenne: #ACFBBD;
$color-avocado: #A2B21E;
$color-aquamarine: #80C2E7;
$color-mango: #F3AC00;
$color-cabbage: #854386;
$color-mushroom: #BAAEA3;
$color-turquoise: #89CBC0;
$color-lighter: #F5F5F5;
$color-light: #E8E8E8;
$color-gravel: #DCDCDC;
$color-kettle: #9D9C9C;
// $color-oven: #636362;
$color-oven: #000000;

$color-charcoal: #3C3C3B;
$color-purple: #411557;
$color-mint: #ACFBBD;
$color-white: #FFFFFF;
$color-black: #000000;
$color-transparent: rgba(0, 0, 0, 0);

$template-colors: (
  cayenne: $color-cayenne,
  avocado: $color-avocado,
  aquamarine: $color-aquamarine,
  mango: $color-mango,
  cabbage: $color-cabbage,
  mushroom: $color-mushroom,
  turquoise: $color-turquoise,
);
